import { ActionTree, ActionContext } from 'vuex';
import { State, ListFilters } from './state';
import axios, {AxiosResponse} from "axios";
import {collect} from 'collect.js'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import qs from "qs";
import PopupMessage from "@/core/helpers/PopupMessage";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Actions {
  fetch(context: ActionContext<State, any>, payload): Promise<AxiosResponse<any>>|null;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit, rootGetters}, payload): Promise<AxiosResponse<any>>|null
  {
    const selectedShop = rootGetters['shopify/shops/selected'];
    if(!selectedShop) {
      PopupMessage.show('Unable to fetch Action Log, shop must be selected', 'error', 'Ok');
      return null;
    }

    commit('setListIsFetching', true);

    type RequestParams = {
      page ?: number;
      limit ?: number;
      sortOrder ?: string;
      sortColumnName ?: string;
      filters ?: null | Record<string, any>;
      relations ?: any[];
    }

    const preprocessFilters = (filters: null | ListFilters ) => {
      if(filters === null) return null;

      // map
      const result = collect(filters).map((value: any, key) => {
        if(value === null || value === '') return null;

        switch (key) {
          case 'createdAtRange':
            // set time from start to the end of the day(s) in current tz, and transform to UTC
            value = {
              from: dayjs(value.from).startOf('day').tz('UTC').format(),
              to: dayjs(value.to).endOf('day').tz('UTC').format(),
            };
            break;
        }
        return value;
      });

      // attach shop
      result.put('shopify_shop_id', selectedShop.id);

      // filter empty
      return result.filter(item => item != null).all();
    };
    const paramsSerializer = params => {
      return qs.stringify(params)
    }


    if(state.listMetadata.filters === null) commit('resetListFilters');

    let params: RequestParams = {
      page: state.listMetadata.pagination.currentPage ?? 1,
      limit: state.listMetadata.pagination.itemsPerPage ?? 10,
      sortOrder: state.listMetadata.sort.order,
      sortColumnName: state.listMetadata.sort.columnName,
      // filters: state.listMetadata.filters,
      filters: preprocessFilters(state.listMetadata.filters),
      relations: [
        'result.execution.rule.tactic',
        'object',
      ]
    };
    if(payload) params = {...params, ...payload};

    return axios.get('/automation/rule-execution-result-actions',{params, paramsSerializer})
      .then((response: AxiosResponse<any>) => {
        commit('setList', response.data.data);
        commit('setListPaginationTotalRecordsCount', response.data.count);
      })
      .catch(() => PopupMessage.show('Failed to fetch Action Log', 'error', 'Ok'))
      .finally(() => commit('setListIsFetching', false));
  },


};