import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import qs from "qs";

export type ToggleTacticAttachmentPayload = {
  shopId: number,
  tacticId: number
}

export interface Actions {
  fetch(context: ActionContext<State, any>, payload): Promise<any>|null;
  enable(context: ActionContext<State, any>, payload: ToggleTacticAttachmentPayload): Promise<AxiosResponse<any>>;
  disable(context: ActionContext<State, any>, payload: ToggleTacticAttachmentPayload): Promise<AxiosResponse<any>>;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({commit, rootGetters}, payload = null): Promise<AxiosResponse<any>>|null {
    commit('setList', []);
    commit('setListPaginationTotalRecordsCount', 0);

    const selectedShop = rootGetters['shopify/shops/selected'];
    if(!selectedShop || !selectedShop.facebookAdAccounts || !selectedShop.facebookAdAccounts.length) {
      return null;
    }

    commit('setListIsFetching', true);

    type RequestParams = {
      page ?: number;
      limit ?: number;
      sortOrder ?: string;
      sortColumnName ?: string;
      filters ?: null | Record<string, any>;
      relations ?: any[];
    }


    const paramsSerializer = params => {
      return qs.stringify(params)
    }

    let params: RequestParams = {};
    if(payload) params = {...params, ...payload};

    return axios.get(`/shopify/shop/${selectedShop.id}/tactics`,{params, paramsSerializer})
      .then((response: AxiosResponse<any>) => {
        commit('setList', response.data.data);
        commit('setListPaginationTotalRecordsCount', response.data.count);
      })
      .catch(() => PopupMessage.show('Failed to fetch Tactics', 'error', 'Ok'))
      .finally(() => commit('setListIsFetching', false));
  },

  enable(context, payload: ToggleTacticAttachmentPayload): Promise<AxiosResponse<any>> {
    const shopId = payload.shopId;
    const tacticId = payload.tacticId;

    return axios.put(`/shopify/shop/${shopId}/tactic/enable`, {tactic_id: tacticId})
      .catch(() => PopupMessage.show('Failed to enable tactic for shop', 'error', 'Ok'))
  },

  disable(context, payload: ToggleTacticAttachmentPayload): Promise<AxiosResponse<any>> {
    const shopId = payload.shopId;
    const tacticId = payload.tacticId;

    return axios.put(`/shopify/shop/${shopId}/tactic/disable`, {tactic_id: tacticId})
      .catch(() => PopupMessage.show('Failed to disable tactic for shop', 'error', 'Ok'))
  }

};