import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import {scopeFiltersProcessor} from "@/store/modules/stats/facebook/actions";
import {merge} from "lodash";
import {entities as datagridEntities} from "@/store/enums/stats/facecebook/Datagrids";
import dayjs from "dayjs";
import {entityTypeMap} from "@/store/enums/Entities";
import {FilterItemPayload} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";

export type ToggleSelectedItemPayload = {
  id: number,
  selected?: boolean,
}


export interface Actions {
  fetch(context: ActionContext<State, any>): Promise<AxiosResponse<any>>|null;
  clear(context: ActionContext<State, any>): void;
  toggleSelectedItem(context: ActionContext<State, any>, payload: ToggleSelectedItemPayload): void;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit, rootState}): Promise<AxiosResponse<any>>|null
  {
    commit('setListIsFetching', true);

    let rootScopeFilters = rootState.stats.facebook.scope.filters ?? []
    const localScopeFilters: FilterItemPayload[] = [];

    // ShopifyShop ID
    if(rootState.shopify.shops.selected) {
      localScopeFilters.push({
        key: `${entityTypeMap.adAccount}.shopify_shop_id`,
        value: rootState.shopify.shops.selected.id,
        settings: {
          api: {
            entity: entityTypeMap.adAccount,
            field: 'shopify_shop_id',
            operator: 'equal',
          },
        }
      });
    }

    // Selected Campaign ID's
    const selectedCampaigns = rootState.stats.facebook.campaigns.selected;
    if(selectedCampaigns && selectedCampaigns.length > 0) {
      localScopeFilters.push({
        key: `${entityTypeMap.campaign}.id`,
        value: selectedCampaigns,
        settings: {
          api: {
            entity: entityTypeMap.campaign,
            field: 'id',
            operator: 'in',
          },
        }
      });
    }

    if(localScopeFilters.length > 0) {
      rootScopeFilters = [...rootScopeFilters, ...localScopeFilters];
    }

    const scopeFilters = scopeFiltersProcessor(rootScopeFilters);

    let metricPeriod = rootState.stats.facebook.metric.period;
    const metricPeriodRange = rootState.stats.facebook.metric.periodRange;
    if(metricPeriod === 'custom' && metricPeriodRange) {
      metricPeriod = [
        dayjs(metricPeriodRange.from).startOf('day').format(),
        dayjs(metricPeriodRange.to).endOf('day').format(),
      ]
    } else if(metricPeriod === 'custom') {
      metricPeriod = 'today';
    }

    const metricPreset = rootState.stats.facebook.metric.preset;

    const params = {
      scope: {
        level: datagridEntities.adSets,
        pagination: {
          limit: state.listMetadata.pagination.itemsPerPage,
          page: state.listMetadata.pagination.currentPage,
        },
        sort: {
          metric: state.listMetadata.sort.metric,
          columnName: (!state.listMetadata.sort.metric) ? state.listMetadata.sort.columnName : null,
          order: state.listMetadata.sort.order,
        },
        filters: scopeFilters,
      },
      metric: {
        period: metricPeriod,
        preset: metricPreset,
      }
    };

    return axios.post('/automation/metric-stats', params)
      .then(async (response: AxiosResponse<any>) => {
        commit('setList', response.data.data);
        commit('setListPaginationTotalRecordsCount', response.data.count);
        commit('setMetricValueIdentifiers', response.data.metricValueIdentifiers);
      })
      .catch((error) => {
        console.log(error);
        return PopupMessage.show('Failed to fetch facebook ads stats', 'error', 'Ok')
      })
      .finally(() => commit('setListIsFetching', false));
  },

  clear({commit}): void {
    commit('clearList');
    commit('setListIsFetching', false);
    commit('setListPaginationTotalRecordsCount', 0);
    commit('setListPaginationCurrentPage', 1);
    commit('setMetricValueIdentifiers', null);
  },

  toggleSelectedItem({state}, payload: ToggleSelectedItemPayload): void {
    const currentSelectedState = state.selected.includes(payload.id);
    const updatedSelectedState = payload.selected ?? !currentSelectedState;

    if(currentSelectedState === updatedSelectedState) return;

    // remove
    if(currentSelectedState && !updatedSelectedState) {
      state.selected.splice(state.selected.findIndex(id => id === payload.id), 1);
    }

    // add
    if(!currentSelectedState && updatedSelectedState) {
      state.selected.push(payload.id);
    }
  },


};