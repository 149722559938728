import {
  FilterGroups,
  FilterItemPayload,
} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";

export type State = {
  selectedDatagrid: null|string
  scope: {
    filters: FilterItemPayload[]|null,
  },
  scopeMetadata: {
    dynamicFilters: FilterGroups|null,
  }
  metric: {
    period: null|string,
    periodRange: null|Record<any, any>,
    preset: null|string,
    // filters: null|Record<any, any>,
  }
  modals: {
    shopify: {
      orders: {
        isVisible: boolean,
        isLoading: boolean,
        title: null|string,
        apiUrl: null|string,
      },
    },
    columns: {
      isVisible: boolean,
      isLoading: boolean,
      title: null|string,
    },
  },
}

export const state: State = {
  selectedDatagrid: null,
  scope: {
    filters: null,
  },
  scopeMetadata: {
    dynamicFilters: null,
  },
  metric: {
    period: 'today',
    periodRange: null,
    preset: 'performance',
    // filters: null,
  },
  modals: {
    shopify: {
      orders: {
        isVisible: false,
        isLoading: false,
        title: null,
        apiUrl: null,
      },
    },
    columns: {
      isVisible: false,
      isLoading: false,
      title: null,
    },
  },
};
