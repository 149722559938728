import {
  Module,
} from 'vuex';
import {module as objectTypes} from "./objectTypes";
import {module as actions} from "./actions";

export const module: Module<any, any> = {
  modules: {
    objectTypes,
    actions,
  },

  namespaced: true,
};