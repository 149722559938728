export type State = {
  list: null|any[];
  listMetadata: {
    isFetching: boolean,
    pagination: {
      totalRecordsCount: number,
      itemsPerPage: number,
      currentPage: number,
    },
    sort: {
      metric: null|string,
      columnName: null|string,
      order: null|string,
    },
    metricValueIdentifiers: null|Record<string, Record<string, string>>,
  },
  selected: number[],
}

export const state: State = {
  list: null,
  listMetadata: {
    isFetching: false,
    pagination: {
      totalRecordsCount: 0,
      itemsPerPage: 10,
      currentPage: 1,
    },
    sort: {
      metric: null,
      columnName: 'effective_status',
      order: 'asc',
    },
    metricValueIdentifiers: null,
  },
  selected: [],
};
