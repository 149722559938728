import { MutationTree } from 'vuex';
import { State } from './state';
import {merge} from "lodash";
import {
  FilterGroups,
  FilterItemPayload
} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";


export type Mutations<S = State> = {
  setScopeFilters(state: S, payload: null|FilterItemPayload[]): void;
  setScopeFilter(state: S, payload: FilterItemPayload): void;
  removeScopeFilter(state: State, filterKey: string): void;
  setMetricPeriod(state: State, payload: null|string): void;
  updateModal(state: State, payload: Record<any, any>): void;
  setScopeDynamicFilters(state: State, payload: null|FilterGroups): void;
}

export const mutations: MutationTree<State> & Mutations = {
  selectDatagrid(state: State, payload: string) {
    state.selectedDatagrid = payload;
  },
  setScopeFilters(state: State, payload: null|FilterItemPayload[]) {
    state.scope.filters = payload;
  },
  setScopeFilter(state: State, payload: FilterItemPayload) {
    if(!state.scope.filters) state.scope.filters = [];
    let scopeFilter = state.scope.filters.find(filter => filter.key === payload.key);

    if(!scopeFilter) {
      state.scope.filters.push(payload);
    } else {
      scopeFilter = merge(scopeFilter, payload)
    }
  },
  removeScopeFilter(state: State, filterKey: string) {
    if(!state.scope.filters) return;

    const filterIndex = state.scope.filters.findIndex(item => item.key === filterKey);
    if(filterIndex === -1) return;

    state.scope.filters.splice(filterIndex, 1);
  },
  setMetricPeriod(state: State, payload: null|string) {
    state.metric.period = payload;
  },
  setMetricPeriodRange(state: State, payload: null|Record<any, any>) {
    state.metric.periodRange = payload;
  },
  updateModal(state: State, payload: Record<any, any>) {
    state.modals = merge(state.modals, payload);
  },
  setScopeDynamicFilters(state: State, payload: null|FilterGroups) {
    state.scopeMetadata.dynamicFilters = payload;
  }
};