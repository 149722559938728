import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  list(state: State): any[];
  listIsFetching(state: State): boolean;
  show(state: State): Record<any, any>
}

export const getters: GetterTree<State, any> & Getters = {
  list: (state) => state.list,
  listIsFetching: (state) => state.listMetadata.isFetching,
  show: (state) => (id) => {
    return state.list.filter(strategy => strategy.id === id)
  }
};