//TODO: refactor store modules that use these enums to be normal vuex modules, and delete that file
enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  REFRESH_ACCESS_TOKEN = "refreshAccessToken",
  REDIRECT_ON_AUTH = "redirectOnAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  ADD_FB_PROFILE = "addFbProfile",
  DELETE_FB_PROFILE = "deleteFbProfile",
  FETCH_FB_PROFILES = "fetchFbProfiles",
  FETCH_FB_AD_ACCOUNTS = "fetchFbAdAccounts",
  SYNC_FB_PROFILE_AD_ACCOUNTS = "syncFbAdAccounts",
  TOGGLE_ACTIVITY_FB_AD_ACCOUNT = "toggleActivityFbAdAccount",
  RECONNECT_FB_PROFILE = "reconnectFbProfile",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_AUTH_REDIRECT_URL = "setAuthRedirectUrl",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_FB_PROFILES = "setFbProfiles",
  SET_FB_PROFILES_LOADING = "setFbProfilesLoading",
  SET_FB_PROFILES_AD_ACCOUNTS_SYNCING = "setFbProfileAdAccountSyncing",
  SET_FB_AD_ACCOUNTS = "setFbAdAccounts",
  SET_FB_AD_ACCOUNTS_LOADING = "setFbAdAccountsLoading",
  APPEND_FB_PROFILE = "appendFbProfile",
  REMOVE_FB_PROFILE = "removeFbProfile",
  UPDATE_FB_PROFILE = "updateFbProfile",
  UPDATE_ACTIVITY_FB_AD_ACCOUNT = "updateActivityFbAdAccount",
}

export { Actions, Mutations };
