import { MutationTree } from 'vuex';
import { State } from './state';

export type Mutations<S = State> = {
  setList(state: S, payload: null|any[]): void;
  clearList(state: S): void;
  setListIsFetching(state: S, payload: boolean): void;
  setListPaginationTotalRecordsCount(state: S, payload: number): void;
  setListPaginationCurrentPage(state: S, payload: number): void;
  setListPaginationItemsPerPage(state: S, payload: number): void;
  setListSortColumnName(state: S, payload: null|string): void;
  setListSortMetric(state: S, payload: null|string): void;
  setListSortOrder(state: S, payload: null|string): void;
  setMetricValueIdentifiers(state: S, payload: null|Record<string, Record<string, string>>): void;

  setSelected(state: S, payload: number[]): void;
  clearSelected(state: S): void;
  select(state: S, payload: number): void;
  deselect(state: S, payload: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setList(state: State, payload: null|any[]) {
    state.list = payload;

    // clear selected that aren't fetched
    if(state.list && state.list.length > 0) {
      if(state.selected && state.selected.length > 0) {
        state.selected.forEach((selectedId, selectedIndex) => {
          const listIndex = state.list?.findIndex(item => item.id === selectedId);
          if(listIndex !== -1) return;
          state.selected.splice(selectedIndex, 1);
        });
      }
    } else {
      state.selected = [];
    }
  },
  clearList(state: State) {
    state.list = null;
  },
  setListIsFetching(state: State, payload: boolean) {
    state.listMetadata.isFetching = payload;
  },
  setListPaginationTotalRecordsCount(state: State, payload: number) {
    state.listMetadata.pagination.totalRecordsCount = payload;
  },
  setListPaginationCurrentPage(state: State, payload: number) {
    state.listMetadata.pagination.currentPage = payload;
  },
  setListPaginationItemsPerPage(state: State, payload: number) {
    state.listMetadata.pagination.itemsPerPage = payload;
  },
  setListSortColumnName(state: State, payload: null|string) {
    state.listMetadata.sort.columnName = payload;
  },
  setListSortMetric(state: State, payload: null|string) {
    state.listMetadata.sort.metric = payload;
  },
  setListSortOrder(state: State, payload: null|string) {
    state.listMetadata.sort.order = payload;
  },
  setMetricValueIdentifiers(state: State, payload: null|Record<string, Record<string, string>>) {
    state.listMetadata.metricValueIdentifiers = payload;
  },

  setSelected(state: State, payload: number[]) {
    state.selected = payload;
  },
  clearSelected(state: State) {
    state.selected = [];
  },
  select(state: State, payload: number) {
    state.selected.push(payload);
  },
  deselect(state: State, payload: number) {
    const index = state.selected.indexOf(payload);
    if (index === -1) return;
    state.selected.splice(index, 1);
  },
};