import { Module } from 'vuex';

import {state} from "./state";
import {getters} from './getters'

export const module: Module<any, any> = {
  state,
  getters,

  namespaced: true,
};