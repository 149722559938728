import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import axios from 'axios';
import dayjs, {Dayjs} from 'dayjs';
import PopupMessage from '@/core/helpers/PopupMessage';
import {unset} from "lodash";
import store from "@/store";

//TODO: move all logic in to modules/facebook/profiles

export type FacebookIntegrationProfile = {
  id: number;
  store_id: number;
  name: string;
  email: string;
  avatar: string;
  fb_id: string;
  expires_at: Dayjs;
  has_expired: boolean;
}

type FacebookIntegrationAdAccount = {
  id: number;
  facebook_profile_id: string;
  name: string;
  is_active: boolean;
  status: string;
}

interface FacebookIntegration {
  loadingProfiles: boolean;
  profiles: FacebookIntegrationProfile[];

  loadingAdAccounts: boolean;
  adAccounts: FacebookIntegrationAdAccount[];
}

@Module
export default class FacebookIntegrationModule extends VuexModule implements FacebookIntegration {
  loadingProfiles = false;
  profiles = [] as FacebookIntegrationProfile[];

  loadingAdAccounts = false;
  adAccounts = [] as FacebookIntegrationAdAccount[];
  syncingAdAccounts = {};

  get getFbProfiles(): Array<FacebookIntegrationProfile> {
    return this.profiles;
  }

  get getFbAdAccounts(): Array<FacebookIntegrationAdAccount> {
    return this.adAccounts;
  }

  get getLoadingFbProfiles(): boolean {
    return this.loadingProfiles;
  }

  get getLoadingFbAdAccounts(): boolean {
    return this.loadingAdAccounts;
  }

  get getSyncingFbProfileAdAccounts() {
    return profileId => this.syncingAdAccounts[profileId] ?? false;
  }

  @Mutation
  [Mutations.SET_FB_PROFILES](items) {
    this.profiles = items.map(i => {
      i.expires_at = dayjs(i.expires_at, 'YYYY-MM-DDThh:mm:ss');
      return i;
    });
  }

  @Mutation
  [Mutations.SET_FB_AD_ACCOUNTS](items) {
    this.adAccounts = items;
  }

  @Mutation
  [Mutations.APPEND_FB_PROFILE](item) {
    item.expires_at = dayjs.unix(item.expires_at);
    this.profiles.push(item as FacebookIntegrationProfile);
  }

  @Mutation
  [Mutations.REMOVE_FB_PROFILE](id) {
    const index = this.profiles.findIndex(profile => profile.id === id);
    this.profiles.splice(index, 1);
  }

  @Mutation
  [Mutations.UPDATE_FB_PROFILE](fbProfile) {
    const index = this.profiles.findIndex(profile => profile.id === fbProfile.id);
    this.profiles[index] = fbProfile;
  }

  @Mutation
  [Mutations.SET_FB_PROFILES_LOADING](value) {
    this.loadingProfiles = value;
  }

  @Mutation
  [Mutations.SET_FB_AD_ACCOUNTS_LOADING](value) {
    this.loadingAdAccounts = value;
  }

  @Mutation
  [Mutations.UPDATE_ACTIVITY_FB_AD_ACCOUNT](payload) {
    const index = this.adAccounts.findIndex(i => i.id === payload.id);
    this.adAccounts[index].is_active = payload.isActive;
  }

  @Mutation
  [Mutations.SET_FB_PROFILES_AD_ACCOUNTS_SYNCING](payload) {
    if(!payload.isSyncing && payload.profileId in this.syncingAdAccounts) {
      unset(this.syncingAdAccounts, payload.profileId);
      return;
    }
    if(!payload.isSyncing) return;

    this.syncingAdAccounts[payload.profileId] = payload.isSyncing;
  }

  @Action
  [Actions.FETCH_FB_PROFILES]() {
    this.context.commit(Mutations.SET_FB_PROFILES, []);
    this.context.commit(Mutations.SET_FB_PROFILES_LOADING, true);

    return axios.get('/integration/facebook/profiles')
      .then(response => {
        this.context.commit(Mutations.SET_FB_PROFILES, response.data.data);
      })
      .catch(() => PopupMessage.show('Failed to fetch Facebook ad accounts', 'error', 'Ok'))
      .finally(() => this.context.commit(Mutations.SET_FB_PROFILES_LOADING, false));
  }

  @Action
  [Actions.SYNC_FB_PROFILE_AD_ACCOUNTS](profileId) {
    this.context.commit(Mutations.SET_FB_PROFILES_AD_ACCOUNTS_SYNCING, {profileId, isSyncing: true});

    return axios.post(`/integration/facebook/profiles/${profileId}/sync-ad-accounts`)
      .then(() => {
        this.context.dispatch(Actions.FETCH_FB_AD_ACCOUNTS);
        this.context.dispatch('facebook/dropdown/adAccounts/clear');
      })
      .catch(() => PopupMessage.show(`Failed to sync Facebook ad accounts for a profile ${profileId}`, 'error', 'Ok'))
      .finally(() => this.context.commit(Mutations.SET_FB_PROFILES_AD_ACCOUNTS_SYNCING, {profileId, isSyncing: false}));
  }

  @Action
  [Actions.RECONNECT_FB_PROFILE]({accessToken, profileId}) {
    return axios.post(`/integration/facebook/profiles/${profileId}/reconnect`, {access_token: accessToken})
      .then(response => {
        if (response.data.status === 'success') {
          this.context.commit(Mutations.UPDATE_FB_PROFILE, response.data.fb_profile);
          this.context.dispatch('facebook/dropdown/adAccounts/clear');
        }
        return response;
      })
      .catch(() => PopupMessage.show('Failed to reconnect Facebook profile', 'error', 'Ok'))
  }

  @Action
  [Actions.ADD_FB_PROFILE](accessToken) {
    return axios.post('/integration/facebook/profiles', {access_token: accessToken})
      .then(response => {
        if (response.data.status === 'created' || response.data.status === 'restored') {
          this.context.commit(Mutations.APPEND_FB_PROFILE, response.data.fb_profile);
          this.context.dispatch('facebook/dropdown/adAccounts/clear');
        }
        return response;
      })
      .catch(() => PopupMessage.show('Failed to add Facebook profile', 'error', 'Ok'))
  }

  @Action
  [Actions.DELETE_FB_PROFILE](id) {
    return axios.delete(`/integration/facebook/profiles/${id}`)
      .then(() => {
        this.context.commit(Mutations.REMOVE_FB_PROFILE, id);
        this.context.dispatch('facebook/dropdown/adAccounts/clear');
      })
      .catch(() => PopupMessage.show('Failed to delete Facebook profile', 'error', 'Ok'))
  }

  @Action
  [Actions.FETCH_FB_AD_ACCOUNTS]() {
    this.context.commit(Mutations.SET_FB_AD_ACCOUNTS_LOADING, true);

    return axios.get('/integration/facebook/ad-accounts')
      .then(response => {
        this.context.commit(Mutations.SET_FB_AD_ACCOUNTS, response.data.data);
      })
      .catch(() => PopupMessage.show('Failed to fetch Facebook ad accounts', 'error', 'Ok'))
      .finally(() => this.context.commit(Mutations.SET_FB_AD_ACCOUNTS_LOADING, false));
  }

  @Action
  [Actions.TOGGLE_ACTIVITY_FB_AD_ACCOUNT](adAccount) {
    const toggledIsActive = !adAccount.is_active;
    const toggleActivity = toggledIsActive ? 'enable' : 'disable';

    this.context.commit(Mutations.UPDATE_ACTIVITY_FB_AD_ACCOUNT, {id: adAccount.id, isActive: toggledIsActive});

    return axios.put(`/integration/facebook/ad-accounts/${adAccount.id}/${toggleActivity}`)
      .then(response => {
        this.context.dispatch('facebook/dropdown/adAccounts/clear');
        return response;
      })
      .catch(() => {
        this.context.commit(Mutations.UPDATE_ACTIVITY_FB_AD_ACCOUNT, {id: adAccount.id, isActive: !toggledIsActive});
        PopupMessage.show('Failed to toggle activity on ad account', 'error', 'Ok');
      });
  }
}
