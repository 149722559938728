import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import qs from "qs";
import {Mutations} from "@/store/enums/StoreEnums";

export interface Actions {
  fetch(context: ActionContext<State, any>, payload: null|Record<string, any>): Promise<AxiosResponse<any>>|null;
  toggleActiveState(context: ActionContext<State, any>, id: number): Promise<AxiosResponse<any>| void>|null;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit, dispatch}, payload: null|Record<string, any>): Promise<AxiosResponse<any>>|null
  {
    const forceLoad = (payload && payload.force);

    if(!forceLoad && state.list.length > 0) return null;

    commit('setListIsFetching', true);

    const paramsSerializer = params => {
      return qs.stringify(params)
    }
    const params = {
      // relations: [
      //   'facebookAdAccount'
      // ]
    };

    return axios.get('/google/ad-accounts', {params, paramsSerializer})
      .then((response: AxiosResponse<any>) => {
        commit('setList', response.data.data);
        commit('setListPaginationTotalRecordsCount', response.data.count);

        dispatch('google/dropdown/adAccounts/clear', null, {root:true});
      })
      .catch(() => PopupMessage.show('Failed to fetch google ad accounts', 'error', 'Ok'))
      .finally(() => commit('setListIsFetching', false));
  },

  toggleActiveState({state, commit, dispatch}, id: number): Promise<AxiosResponse<any> | void>|null {
    const adAccount = state.list.find(item => item.id === id);
    const toggledIsActive = !adAccount.is_active;
    const toggleActivity = toggledIsActive ? 'enable' : 'disable';

    commit('updateListItem', {
      id: adAccount.id,
      payload: {
        is_active: toggledIsActive,
      }
    });

    return axios.put(`/google/ad-accounts/${adAccount.id}/${toggleActivity}`)
      .then(response => {
        dispatch('google/dropdown/adAccounts/clear', null, {root:true});
        return response;
      })
      .catch(() => {
        commit('updateListItem', {
          id: adAccount.id,
          payload: {
            is_active: !toggledIsActive,
          }
        });
        PopupMessage.show('Failed to toggle activity on ad account', 'error', 'Ok');
      });
  },
};