import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  list(state: State): null | Record<string, Record<string, any>>;
  listByEntity(state: State): null | Record<string, Record<string, any>>;
  listIsFetching(state: State): boolean;
}

export const getters: GetterTree<State, any> & Getters = {
  list: (state) => state.list,
  listByEntity: (state) => state.listByEntity,
  listIsFetching: (state) => state.listMetadata.isFetching,
};