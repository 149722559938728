import {
  Module,
} from 'vuex';
import {state} from "./state";
import {actions} from "./actions";
import {getters} from "./getters";
import {mutations} from "./mutations";

export const module: Module<any, any> = {
  state,
  actions,
  getters,
  mutations,

  namespaced: true,
};
