import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  // isFetching(state: State): boolean;
  // list(state: State): any[];
  // listTotalRecordsCount(state: State): number;
}

export const getters: GetterTree<State, any> & Getters = {
  // isFetching: (state) => state.isFetching,
  // list: (state) => state.list,
  // listTotalRecordsCount: (state) => state.listTotalRecordsCount,
};