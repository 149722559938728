import { MutationTree } from 'vuex';
import { State } from './state';

export type Mutations<S = State> = {
  setIsFetching(state: S, payload: boolean): void;
  setList(state: S, payload: any[]): void;
  setListTotalRecordsCount(state: S, payload: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setIsFetching(state: State, payload: boolean) {
    state.isFetching = payload;
  },
  setList(state: State, payload: any[]) {
    state.list = payload;
  },
  setListTotalRecordsCount(state: State, payload: number) {
    state.listTotalRecordsCount = payload;
  },
};