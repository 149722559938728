import {createStaleWhileRevalidateCache, EmitterEvents} from 'stale-while-revalidate-cache'
import {AxiosResponse} from "axios";
import {SwrStorage} from "@/store/cache/swrStorage";

const storage = new SwrStorage({
  key: 'vswr'
});

const staleWhileRevalidate = createStaleWhileRevalidateCache({
  storage,
  minTimeToStale: 3600000, // 1h in milliseconds until cached data is considered stale and refetched
  // maxTimeToLive: Infinity, // default Infinity - cache won't ever be discarded
});

// debug
// staleWhileRevalidate.onAny((event, payload) => {
//   switch (event) {
//     case EmitterEvents.invoke:
//       console.log('swr invoke', payload.cacheKey);
//       break;
//
//     case EmitterEvents.cacheHit:
//       console.log('swr cacheHit', payload.cacheKey, payload.cachedValue);
//       break;
//
//     case EmitterEvents.cacheMiss:
//       console.log('swr cacheMiss', payload.cacheKey);
//       break;
//
//     case EmitterEvents.cacheExpired:
//       console.log('swr cacheExpired', payload);
//       break;
//
//     case EmitterEvents.cacheGetFailed:
//       console.log('swr cacheGetFailed', payload);
//       break;
//
//     case EmitterEvents.cacheSetFailed:
//       console.log('swr cacheSetFailed', payload);
//       break;
//
//     case EmitterEvents.revalidateFailed:
//       console.log('swr revalidateFailed', payload);
//       break;
//
//     case EmitterEvents.revalidate:
//       console.log('swr revalidate', payload);
//       break;
//
//     default:
//       break;
//   }
// });

// extended implementation
export interface SwrCachedRequestConfig {
  cacheKey: string | (() => string),
  fetch: (headers: Record<string, any>) => Promise<AxiosResponse>,
  transformResponse: (response: AxiosResponse) => undefined|Record<string, any>,
  shouldCommitCache: (data: undefined|Record<any, any>) => boolean,
  commit: (data: undefined|Record<any, any>) => void,
}

async function cachedRequest<ReturnValue extends undefined>(
  config: SwrCachedRequestConfig
): Promise<ReturnValue> {

  const cacheKey = (typeof config.cacheKey === 'function') ? String(config['cacheKey']()) : String(config.cacheKey);
  const headers = getRequestHeaders(cacheKey);

  const fn = () => {
    return config['fetch'](headers)
    .then(async (response: AxiosResponse) => {
      if (isResponseStatusNotModified(response)) {
        const cachedData = storage.getItem(cacheKey) ?? null;
        if (cachedData !== null) {
          return {cachedData: cachedData as Record<string, any>};
        } else {
          // fetch response without etag
          return {
            response: await config['fetch']({}).then((response: AxiosResponse) => {
              return response;
            })
          };
        }
      }
      return {response};
    })
    .then((result: {response?: AxiosResponse, cachedData?: Record<string, any>}) => {
      if(result?.cachedData) {
        return result.cachedData;
      }

      if(result?.response) {
        cacheResponseETag(cacheKey, result.response);
        const transformedResponse = config.transformResponse(result.response);
        config['commit'](transformedResponse);
        return transformedResponse;
      }

      return {};
    });
  };

  return new Promise<ReturnValue>((resolve, reject) => {
    staleWhileRevalidate(cacheKey, fn)
      .catch((error) => reject(error))
      .then((data) => {
        const result = data as ReturnValue;

        if(config['shouldCommitCache'](result)) {
          config['commit'](result);
        }

        resolve(result);
      });
  });
}

function getRequestHeaders(cacheKey: string): Record<string, string> {
  const result = {};

  const eTagKey = `${cacheKey}_etag`;
  const etag = storage.getItem(eTagKey);

  if(etag) {
    result['If-None-Match'] = etag;
  }

  return result;
}

function cacheResponseETag(cacheKey: string, response: AxiosResponse<any>) {
  const eTag = response.headers?.etag ?? null;

  if(eTag) {
    const eTagKey = `${cacheKey}_etag`;
    storage.setItem(eTagKey, eTag);
  }
}
function isResponseStatusNotModified(response: AxiosResponse<any>): boolean
{
    return (response.status === 304);
}

export default {
  cachedRequest,
  storage,
  getRequestHeaders,
  isResponseStatusNotModified,
};