import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import qs from "qs";
import {merge} from "lodash";

export type FetchPayload = null | {
  force?: boolean,
}

export type ToggleActiveStatePayload = {
  shopId: number,
}

export type UpdateAutomationModePayload = {
  shopId: number,
  mode: string
}

export type UpdateSettingsPayload = {
  shopId: number,
  settings: Record<string, any>
}

export type LinkAdAccountPayload = {
  shopId: number,
  adAccountId: number
}

export type UnlinkAdAccountPayload = {
  shopId: number,
  adAccountId: number
}

export type DeletePayload = {
  shopId: number,
}

export interface Actions {
  fetch(context: ActionContext<State, any>, payload: null|Record<string, any>): Promise<AxiosResponse<any>>|null;
  toggleActiveState(context: ActionContext<State, any>, payload: ToggleActiveStatePayload): Promise<AxiosResponse<any> | void>|null;
  updateAutomationMode(context: ActionContext<State, any>, payload: UpdateAutomationModePayload): Promise<AxiosResponse<any>>|null;
  updateSettings(context: ActionContext<State, any>, payload: UpdateSettingsPayload): Promise<AxiosResponse<any>>|null;
  linkFacebookAdAccount(context: ActionContext<State, any>, payload: LinkAdAccountPayload): Promise<AxiosResponse<any>>|null;
  unlinkFacebookAdAccount(context: ActionContext<State, any>, payload: UnlinkAdAccountPayload): Promise<AxiosResponse<any>>|null;
  delete(context: ActionContext<State, any>, payload: DeletePayload): Promise<AxiosResponse<any>>|null;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit, dispatch}, payload: FetchPayload): Promise<AxiosResponse<any>>|null {
    const forceLoad = payload?.force ?? false;

    if(!forceLoad && state.list.length > 0) return null;

    commit('setListIsFetching', true);

    const paramsSerializer = params => {
      return qs.stringify(params)
    }
    const params = {
      relations: [
        'facebookAdAccounts',
        'googleAdAccounts'
      ]
    };

    return axios.get('/shopify/stores', {params, paramsSerializer})
      .then(async (response: AxiosResponse<any>) => {
        commit('setList', response.data);
        await dispatch('selectDefaultShop')
      })
      .catch((error) => {
        console.log(error);
        return PopupMessage.show('Failed to fetch shops', 'error', 'Ok')
      })
      .finally(() => commit('setListIsFetching', false));
  },

  selectDefaultShop({state, commit}) {
    // select first shop
    if(!state.selected && state.list.length > 0) {
      commit('select', state.list[0]);
    }
  },

  fetchDefaultSettings({state, commit}, payload: FetchPayload): Promise<AxiosResponse<any>>|null {
    const forceLoad = payload?.force ?? false;

    if(!forceLoad && state.default.settings) return null;

    commit('setDefaultSettingsIsFetching', true);

    return axios.get('/shopify/shop/default-settings')
      .then((response: AxiosResponse<any>) => {
        commit('setDefaultSettings', response.data.settings);
      })
      .catch(() => PopupMessage.show('Failed to fetch default shop settings', 'error', 'Ok'))
      .finally(() => commit('setDefaultSettingsIsFetching', false));
  },


  toggleActiveState({commit, state}, payload: ToggleActiveStatePayload): Promise<AxiosResponse<any> | void>|null {
    const shopId = payload.shopId;
    const shop = state.list.find(item => item.id === shopId);
    if(!shop) return null;

    const toggledIsActive = !shop.is_active;
    const toggleActivity = toggledIsActive ? 'enable' : 'disable';

    commit('shopify/shops/updateListItem', {
      id: shopId,
      payload: {
        is_active: toggledIsActive,
      }
    });

    return axios.put(`/shopify/store/${shop.id}/${toggleActivity}`)
      .catch(() => {
        commit('shopify/shops/updateListItem', {
          id: shopId,
          payload: {
            is_active: !toggledIsActive,
          }
        });
        PopupMessage.show('Failed to toggle activity on store', 'error', 'Ok');
      });
  },

  updateAutomationMode({commit}, payload: UpdateAutomationModePayload): Promise<AxiosResponse<any>>|null {
    const shopId = payload.shopId;
    const automationMode = payload.mode;

    commit('setSelectedIsUpdating', true);
    return axios.put(`/shopify/store/${shopId}/updateAutomationMode`, {mode: automationMode})
      .then(() => {
        commit('updateListItem', {
          id: shopId,
          payload: {
            automation_mode: automationMode
          }
        });
      })
      .catch(() => PopupMessage.show('Failed to update shop automation mode', 'error', 'Ok'))
      .finally(() => commit('setSelectedIsUpdating', false));
  },

  updateSettings({commit, state}, payload: UpdateSettingsPayload): Promise<AxiosResponse<any>>|null {
    const shopId = payload.shopId;
    const shop = state.list.find(item => item.id === shopId);
    if(!shop) return null;

    const settings = merge({}, shop?.settings || {}, payload.settings);

    commit('setSelectedIsUpdating', true);
    return axios.put(`/shopify/shop/${shopId}/settings`, {settings})
      .then((response) => {
        // update persisted list & current
        commit('updateListItem', {
          id: shopId,
          payload: {
            settings: response.data.settings
          }
        });
        // clear updated metadata
        commit('updateSelected', {
          settings: null,
        });
      })
      .catch(() => PopupMessage.show('Failed to update shop settings', 'error', 'Ok'))
      .finally(() => commit('setSelectedIsUpdating', false));
  },

  linkFacebookAdAccount({commit}, payload: LinkAdAccountPayload): Promise<AxiosResponse<any>>|null {
    const shopId = payload.shopId;
    const adAccountId = payload.adAccountId;

    return axios.put(`/shopify/store/${shopId}/facebook-ad-account/link`, {facebook_ad_account_id: adAccountId})
      .then(response => {
        commit('updateListItem', {
          id: shopId,
          payload: {
            facebookAdAccounts: response.data.facebookAdAccounts,
          }
        });
      })
      .catch(() => PopupMessage.show('Failed to link Facebook ad account to store', 'error', 'Ok'))
  },

  unlinkFacebookAdAccount({commit}, payload: UnlinkAdAccountPayload): Promise<AxiosResponse<any>>|null {
    const shopId = payload.shopId;
    const adAccountId = payload.adAccountId;
    return axios.put(`/shopify/store/${shopId}/facebook-ad-account/unlink`, {facebook_ad_account_id: adAccountId})
      .then((response) => {
        commit('updateListItem', {
          id: shopId,
          payload: {
            facebookAdAccounts: response.data.facebookAdAccounts,
          }
        });
      })
      .catch(() => PopupMessage.show('Failed to unlink Facebook ad account from store', 'error', 'Ok'))
  },

  linkGoogleAdAccount({commit}, payload: LinkAdAccountPayload): Promise<AxiosResponse<any>>|null {
    const shopId = payload.shopId;
    const adAccountId = payload.adAccountId;

    return axios.put(`/shopify/store/${shopId}/google-ad-account/link`, {google_ad_account_id: adAccountId})
      .then(response => {
        commit('updateListItem', {
          id: shopId,
          payload: {
            googleAdAccounts: response.data.googleAdAccounts,
          }
        });
      })
      .catch(() => PopupMessage.show('Failed to link Google ad account to store', 'error', 'Ok'))
  },

  unlinkGoogleAdAccount({commit}, payload: UnlinkAdAccountPayload): Promise<AxiosResponse<any>>|null {
    const shopId = payload.shopId;
    const adAccountId = payload.adAccountId;
    return axios.put(`/shopify/store/${shopId}/google-ad-account/unlink`, {google_ad_account_id: adAccountId})
      .then((response) => {
        commit('updateListItem', {
          id: shopId,
          payload: {
            googleAdAccounts: response.data.googleAdAccounts,
          }
        });
      })
      .catch(() => PopupMessage.show('Failed to unlink Google ad account from store', 'error', 'Ok'))
  },

  delete({commit}, payload: DeletePayload): Promise<AxiosResponse<any>>|null {
    const shopId = payload.shopId
    return axios.delete(`/shopify/store/${shopId}`)
      .then(() => {
        commit('removeListItem', {id: shopId});
      })
      .catch(() => PopupMessage.show('Failed to delete Shopify store', 'error', 'Ok'))
  },

};