import { App } from "vue";
import round from 'lodash/round';

/**
 * @description service to call HTTP request via Axios
 */
class Filters {
  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    app.config.globalProperties.$filters = {
      money: Filters.money,
      number: Filters.number,
      percent: Filters.percent,
      shortMoney: Filters.shortMoney
    };
  }

  public static number({value, decimals = 2, prefix = '', suffix = '', separator = ','}: NumberFilterNamedParameters) {
    const rounded = +(value).toFixed(decimals);
    return prefix + (rounded).toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator) + suffix;
  }

  public static money(value, decimals = 2, separator = ',') {
    return Filters.number({value, prefix: '$', decimals: decimals});
  }

  public static shortMoney(value) {
    if (!value) return null;

    let newValue = value;
    const suffixes = ['', "K", "M", "B","T"];
    let suffixNum = 0;
    while (newValue >= 100) {
      newValue /= 1000;
      suffixNum++;
    }

    newValue = newValue < 1 ? newValue.toFixed(1) : newValue.toFixed(2);

    newValue += suffixes[suffixNum];
    return newValue;
  }

  public static percent(value, decimals = 2) {
    return Filters.number({value: 100 * value, suffix: '%', decimals: decimals});
  }
}

interface NumberFilterNamedParameters {
  value: number;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  separator?: string;
}

export default Filters;
