import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import qs from "qs";

export interface Actions {
  fetch(context: ActionContext<State, any>, payload: null|Record<string, any>): Promise<AxiosResponse<any>>|null;
  syncAdAccounts(context: ActionContext<State, any>, payload: number): Promise<AxiosResponse<any>>;
  delete(context: ActionContext<State, any>, payload: number): Promise<AxiosResponse<any> | void>|null;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit, dispatch}, payload: null|Record<string, any>): Promise<AxiosResponse<any>>|null
  {
    const forceLoad = (payload && payload.force);

    if(!forceLoad && state.list.length > 0) return null;

    commit('setListIsFetching', true);

    const paramsSerializer = params => {
      return qs.stringify(params)
    }
    const params = {
      // relations: [
      //   'facebookAdAccount'
      // ]
    };

    return axios.get('/google/accounts', {params, paramsSerializer})
      .then((response: AxiosResponse<any>) => {
        commit('setList', response.data.data);
        commit('setListPaginationTotalRecordsCount', response.data.count);

        dispatch('google/dropdown/adAccounts/clear', null, {root:true});
      })
      .catch(() => PopupMessage.show('Failed to fetch google accounts', 'error', 'Ok'))
      .finally(() => commit('setListIsFetching', false));
  },

  syncAdAccounts({dispatch}, payload: number): Promise<AxiosResponse<any>>
  {
    return axios.post(`/google/accounts/${payload}/sync-ad-accounts`)
      .then((response: AxiosResponse<any>) => {
        dispatch('google/dropdown/adAccounts/clear', null, {root:true});
        return response;
      })
      .catch(() => PopupMessage.show('Failed to sync google ad accounts', 'error', 'Ok'));
  },

  delete({commit, dispatch}, id: number): Promise<AxiosResponse<any> | void>|null {
    return axios.delete(`/google/accounts/${id}`)
    .then(() => {
      commit('removeListItem', {id});

      dispatch('google/dropdown/adAccounts/clear', null, {root:true});
    })
    .catch(() => PopupMessage.show('Failed to delete google account', 'error', 'Ok'))
  }
};