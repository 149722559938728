import { MutationTree } from 'vuex';
import { State } from './state';
import {merge} from "lodash";
import {updateListItemById, UpdateListItemByIdPayload, removeListItemById, RemoveListItemByIdPayload} from '@/store/common/mutation/list';

export type Mutations<S = State> = {
  setList(state: S, payload: any[]): void;
  setListIsFetching(state: S, payload: boolean): void;
  select(state: S, payload: null | Record<string, any>): void;
  setSelectedIsUpdating(state: S, payload: boolean): void;
  updateSelected(state: S, payload: Record<string, any>): void;
  updateSelectedValidation(state: S, payload: Record<string, any>): void;
  updateListItem(state: S, payload: UpdateListItemByIdPayload): void;
  setDefaultSettings(state: S, payload: Record<string, any>): void;
  setDefaultSettingsIsFetching(state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setList(state: State, payload: any[]) {
    state.list = payload;

    if(state.selected) {
      const selectedId = state.selected.id;
      const selected = state.list.find(item => item.id === selectedId);
      state.selected = selected ?? null;
    }
  },
  setListIsFetching(state: State, payload: boolean) {
    state.listMetadata.isFetching = payload;
  },
  select(state: State, payload: null | Record<string, any>) {
    state.selected = payload;

    state.selectedMetadata.update = null;
    state.selectedMetadata.isUpdating = false;
    Object.keys(state.selectedMetadata.validation).forEach((validationKey) => {
      state.selectedMetadata.validation[validationKey] = null;
    })

  },
  updateSelected(state: State, payload: Record<string, any>) {
    state.selectedMetadata.update = merge({}, state.selectedMetadata.update || {}, payload);
  },
  updateSelectedValidation(state: State, payload: Record<string, any>) {
    state.selectedMetadata.validation[payload.field] = payload.valid;
  },
  setSelectedIsUpdating(state: State, payload: boolean) {
    state.selectedMetadata.isUpdating = payload;
  },
  updateListItem(state: State, payload: UpdateListItemByIdPayload) {
    updateListItemById(state, payload);
  },
  removeListItem(state: State, payload: RemoveListItemByIdPayload) {
    removeListItemById(state, payload);
  },
  setDefaultSettings(state: State, payload: Record<string, any>) {
    state.default.settings = payload;
  },
  setDefaultSettingsIsFetching(state: State, payload: boolean) {
    state.defaultMetadata.settings.isFetching = payload;
  },
};