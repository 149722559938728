export type ListFilterCreatedAtRange = null | {
  from: Date,
  to: Date,
};
export type ListFilters = null | {
    createdAtRange ?: ListFilterCreatedAtRange,
    search ?: null | string,
    tactic ?: null | string,
    rule ?: null|string,
    objectType ?: null | string,
    status ?: null | string
}

export type State = {
  list: any[];
  listMetadata: {
    isFetching: boolean,
    pagination: {
      totalRecordsCount: number,
      itemsPerPage: number,
      currentPage: number,
    },
    sort: {
      columnName: string,
      order: string,
    },
    filtersDefault: ListFilters,
    filters: ListFilters,
  },
}

export const state: State = {
  list: [],
  listMetadata: {
    isFetching: false,
    pagination: {
      totalRecordsCount: 0,
      itemsPerPage: 10,
      currentPage: 1,
    },
    sort: {
      columnName: 'id',
      order: 'desc',
    },
    filtersDefault: {},
    filters: null,
  },


};
