import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import swr from "@/store/cache/swr";

export interface Actions {
  fetch(context: ActionContext<State, any>, payload): Promise<void | Record<any, any>>|null;
  updatePreset(context: ActionContext<State, any>, payload): Promise<any>|null;
}

const cacheKey = 'automation.metric-stats.presets.list';

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit}): Promise<void | Record<any, any>>|null {
    return swr.cachedRequest({
      cacheKey,
      fetch: (headers): Promise<AxiosResponse> => {

        commit('setListIsFetching', true);
        return axios.get('/automation/metric-stats/presets', {headers})
          .catch(() => PopupMessage.show('Failed to fetch metric-stats presets', 'error', 'Ok'))
          .finally(() => commit('setListIsFetching', false));

      },
      transformResponse: (response: AxiosResponse): Record<string, any> => {
        return response.data;
      },
      shouldCommitCache: (data: undefined|Record<string, any>): boolean => {
        return (state.list === null && data !== null);
      },
      commit: (data: undefined|Record<string, any>) => {
        commit('setList', data);
      }
    });

  },

  updatePreset({state, commit}, payload: Record<string, any>): Promise<AxiosResponse<any>>|null {
    const preset = payload.preset;
    const entity = payload.entity;
    const presetData = state.listMetadata.updated?.[preset]?.[entity] ?? null

    if(!presetData) return null;

    commit('setListIsUpdating', true);
    return axios.put(`/automation/metric-stats/presets/${preset}/${entity}`, {preset: presetData})
      .then((response: AxiosResponse<any>) => {
        // persist "user defined" preset
        commit('persistPreset' ,{
          preset,
          entity,
          list: response.data,
        });
        // clear cached metric-stats presets
        swr.storage.setItem(cacheKey, null);

        // "unset" updated preset, so that "updated" "user defined" one is reapplied
        commit('updatePreset' ,{
          preset,
          entity,
          list: null,
        });
      })
      .catch(() => PopupMessage.show('Failed to update metric-stats preset', 'error', 'Ok'))
      .finally(() => commit('setListIsUpdating', false));
  },


};