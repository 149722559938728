import {
  Module,
} from 'vuex';
import {module as automationStatuses} from "./automationStatuses";


export const module: Module<any, any> = {
  modules: {
    automationStatuses,
  },

  namespaced: true,
};