import {
  Module,
} from 'vuex';

import {state} from './state';
import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

import {module as ads} from './ads';
import {module as adSets} from './adSets';
import {module as campaigns} from './campaigns';


export const module: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,

  modules: {
    ads,
    adSets,
    campaigns,
  },

  namespaced: true,
};
