import dayjs from 'dayjs';
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import router from "@/router";
import ApiService from "@/core/services/ApiService";
import RouteService from "@/core/services/RouteService";

const ACCESS_TOKEN_KEY = "access_token" as string;
const EXPIRES_AT_KEY = "token_expires_at" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

/**
 * @description save token into localStorage
 * @param ttl: number
 */
export const saveTokenTTL = (ttl: number): void => {
  const expiresAt = dayjs().add(ttl, 'seconds').unix().toString();
  window.localStorage.setItem(EXPIRES_AT_KEY, expiresAt);
};

/**
 * @description check if token has expired
 */
export const tokenExpired = (): boolean => {
  const timestamp = Number(window.localStorage.getItem(EXPIRES_AT_KEY));
  const now = dayjs().unix();

  return now > timestamp;
};

/**
 * @description set token refresh timer
 */
export const setTokenRefreshTimer = (): void => {
  const timestamp = Number(window.localStorage.getItem(EXPIRES_AT_KEY));

  const refreshAt = timestamp - dayjs().unix() - 60; // refresh 1 minute before token expires

  const timeoutId = setTimeout(() => {
    store.dispatch(Actions.REFRESH_ACCESS_TOKEN);
  }, refreshAt * 1000);

  const intervalId = setInterval(() => {
    const timestamp = Number(window.localStorage.getItem(EXPIRES_AT_KEY));
    const refreshAt = timestamp - dayjs().unix() - 60;

    if (refreshAt < 0) {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    } else {
      // console.log('refresh token in '+refreshAt);
    }
  }, 60000);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(EXPIRES_AT_KEY);
};

/**
 * @description init JWT
 */
export const init = (): void => {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  const expiresAt = window.localStorage.getItem(EXPIRES_AT_KEY);

  if (!RouteService.isGuestRoute() && (!accessToken || !expiresAt || tokenExpired())) {
    store.commit(Mutations.PURGE_AUTH);
    store.dispatch('persistence/resetStore');
    router.push({name: 'sign-in'});
  } else {
    ApiService.setHeader();
    setTokenRefreshTimer();
  }
};

export default { init, getToken, saveToken, saveTokenTTL, tokenExpired, setTokenRefreshTimer, destroyToken };
