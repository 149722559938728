import { MutationTree } from 'vuex';
import { State } from './state';

type SetListFilter = {
  key: string
  value: any
}

export type Mutations<S = State> = {
  setList(state: S, payload: any[]): void;
  setListIsFetching(state: S, payload: boolean): void;
  setListPaginationTotalRecordsCount(state: S, payload: number): void;
  setListPaginationItemsPerPage(state: S, payload: number): void;
  setListPaginationCurrentPage(state: S, payload: number): void;
  setListSortColumnName(state: S, payload: string): void;
  setListSortOrder(state: S, payload: string): void;
  setListFilters(state: S, payload: Record<string, any>): void;
  resetListFilters(state: S): void;
  setListFilter(state: S, payload: SetListFilter): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setList(state: State, payload: any[]) {
    state.list = payload;
  },
  setListIsFetching(state: State, payload: boolean) {
    state.listMetadata.isFetching = payload;
  },
  setListPaginationTotalRecordsCount(state: State, payload: number) {
    state.listMetadata.pagination.totalRecordsCount = payload;
  },
  setListPaginationItemsPerPage(state: State, payload: number) {
    state.listMetadata.pagination.itemsPerPage = payload;
  },
  setListPaginationCurrentPage(state: State, payload: number) {
    state.listMetadata.pagination.currentPage = payload;
  },
  setListSortColumnName(state: State, payload: string) {
    state.listMetadata.sort.columnName = payload;
  },
  setListSortOrder(state: State, payload: string) {
    state.listMetadata.sort.order = payload;
  },
  setListFilters(state: State, payload: Record<string, any>) {
    state.listMetadata.filters = payload;
  },
  resetListFilters(state: State): void {
    state.listMetadata.pagination.currentPage = 1;
    state.listMetadata.filters = {...state.listMetadata.filtersDefault};
  },
  setListFilter(state: State, payload: SetListFilter): void {
    if(state.listMetadata.filters === null) state.listMetadata.filters = {...state.listMetadata.filtersDefault};
    state.listMetadata.filters[payload.key] = payload.value;
  }
};