import {
  Module,
} from 'vuex';
import {module as facebook} from "./facebook";


export const module: Module<any, any> = {
  modules: {
    facebook,
  },

  namespaced: true,
};
