import {createI18n} from "vue-i18n";
import MessageFormat from 'messageformat';

const messages = {
  en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    integrations: "Integrations",
    facebook: "Facebook",
    facebookStats: "Ads Stats",
    actionLogs: "Action Logs",
    shopify: "Shopify",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    activity: "Activity",
    logs: "Logs",
    strategies: "Strategies",
    'All': 'All',

    // date presets
    datePreset: {
      today: 'Today',
      yesterday: 'Yesterday',
      last_7d: 'Last 7 Days',
      last_14d: 'Last 14 Days',
      last_30d: 'Last 30 Days',
    },

    // object types
    objectType: {
      // facebook
      facebook: {
        ad: 'Ad',
        adSet: 'Ad Set',
        campaign: 'Campaign',
        adAccount: 'Account',
      }
    },

    // event types
    eventTypes: {
      PageView: 'Viewed page',
      AddToCart: 'Added to cart',
      InitiateCheckout: 'Initiated checkout',
      AddShippingInfo: 'Added shipping info',
      AddPaymentInfo: 'Added payment info',
      Purchase: 'Purchased',
      ViewCart: 'Viewed cart',
      ViewContent: 'Viewed product'
    },

    // actions: facebook change requests
    'action.fbcr.campaign.update' : 'Campaign Update',
    'action.fbcr.campaign.name.update' : 'Campaign Name Update',
    'action.fbcr.campaign.status.update' : 'Campaign Status Update',
    'action.fbcr.campaign.status.update.start' : 'Campaign Pause',
    'action.fbcr.campaign.status.update.pause' : 'Campaign Start',
    'action.fbcr.campaign.budget.update' : 'Campaign Budget Update',

    'action.fbcr.adSet.update' : 'Ad Set Update',
    'action.fbcr.adSet.name.update' : 'Ad Set Name Update',
    'action.fbcr.adSet.status.update' : 'Ad Set Status Update',
    'action.fbcr.adSet.status.update.start' : 'Ad Set Pause',
    'action.fbcr.adSet.status.update.pause' : 'Ad Set Start',
    'action.fbcr.adSet.budget.update' : 'Ad Set Budget Update',
    'action.fbcr.adSet.bid.update' : 'Ad Set Bid Update',

    'action.fbcr.ad.update' : 'Ad Update',
    'action.fbcr.ad.name.update' : 'Ad Name Update',
    'action.fbcr.ad.status.update' : 'Ad Status Update',
    'action.fbcr.ad.status.update.start' : 'Ad Start',
    'action.fbcr.ad.status.update.pause' : 'Ad Pause',

    // metrics
    // TODO: check if still in use, cleanup if not
    metrics: {
      ad_id: 'Ad ID',
      is_l7d_below_target_roas: 'Below Target ROAS? (Last 7 Days)',
      is_loosing_l7d: 'Product Is Losing (Last 7 Days)',
      is_loosing_l30d: 'Product Is Losing (Last 30 Days)',
      is_loosing_today: 'Product Is Losing (Today)',
      is_today_above_target_roas: 'Above Target Roas? (Today)',
      is_today_below_target_roas: 'Below Target Roas? (Today)',
      l7d_fb_profit: 'Facebook Profit (Last 7 Days)',
      l7d_fb_roas: 'Facebook ROAS (Last 7 Days)',
      l7d_fb_spend: 'Facebook Spend (Last 7 Days)',
      l7d_utm_profit: 'UTM Profit (Last 7 Days)',
      l7d_utm_roas: 'UTM ROAS (Last 7 Days)',
      shopify_product_id: 'Shopify Product ID',
      today_ad_minimum_spend_reached: 'Ad Minimum Spend Reached? (Today)',
      today_fb_profit: 'Facebook Profit (Today)',
      today_fb_roas: 'Facebook ROAS (Today)',
      today_fb_spend: 'Facebook Spend (Today)',
      today_utm_profit: 'UTM Profit (Today)',
      today_utm_roas: 'UTM ROAS (Today)',
      l7d_adj_profit: 'Adjusted Profit (Last 7 Days)',
      shopify_shop_id: 'Shop Id',
      today_adj_profit: 'Adjusted Profit (Today)',
      today_fb_ad_cost: 'Ad Cost (Today)',
      is_loosing_shop_today: 'Shop Is Losing (Today)',
      has_today_min_spend_reached: 'Minimum Spend Reached? (Today)',
      today_ad_minimum_spend_amount: 'Ad Minimum Spend Amount? (Today)',
      is_yesterday_above_target_roas: 'Above Target Roas? (Yesterday)'
    },

    // TODO: replace with metrics glossary
    'automation.metric.static-value.btn_dp_on': 'Static: btn_dp_on',
    'automation.metric.static-value.btn_dp_by_store_margin': 'Static: btn_dp_by_store_margin',
    'automation.metric.static-value.btn_dp_by_profit': 'Static: btn_dp_by_profit',
    'automation.metric.static-value.btn_dp_min_spend_by_perc_or_amount': 'Static: btn_dp_min_spend_by_perc_or_amount',

    'automation.metric.shopify.product.budget.today': 'Product Budget: Today',
    'automation.metric.shopify.product.roas.today': 'Product ROAS: Today',

    'automation.metric.shopify.shop.blended-ad-cost.today': 'Shop Blended Ad Cost: Today',
    'automation.metric.shopify.shop.adjusted-target-roas.last_7d': 'Shop Adjusted Target Roas: Last 7 days',
    'automation.metric.shopify.shop.adjusted-target-roas.today': 'Shop Adjusted Target Roas: Today',
    'automation.metric.shopify.shop.facebook-ad-max-profit-loss-multiplier.last_7d': 'Shop: Facebook Ad Max Profit Loss Multiplier: Last 7 days',
    'automation.metric.shopify.shop.facebook-ad-cost.today': 'Shop: Facebook Ad Cost: Today',
    'automation.metric.shopify.shop.adjusted-target-roas.yesterday': 'Shop: Adjusted Target ROAS',

    'automation.metric.shopify.shop.setting.automation.target_ad_cost': 'Shop Setting: Target Ad Cost',
    'automation.metric.shopify.shop.setting.automation.min_spend_daily_pause': 'Shop Setting: Min Spend Daily Pause',
    'automation.metric.shopify.shop.setting.automation.daily_management.daily_pause.profit_loss_look_back_days': 'Shop Setting: Profit Loss Look Back Days',
    'automation.metric.shopify.shop.setting.automation.daily_management.daily_pause.profit_margin_min': 'Shop Setting: Min Profit Margin',
    'automation.metric.shopify.shop.setting.automation.daily_management.daily_pause.roas_static': 'Shop Setting: Static ROAS',
    'automation.metric.shopify.shop.setting.automation.daily_management.product_profit_min': 'Shop Setting: Min Product Profit',
    'automation.metric.shopify.shop.setting.daily-management.target-ad-cost-inverse': 'Shop Setting: Inverse Target Ad Cost',
    'automation.metric.shopify.shop.setting.automation.daily_management.product_spend_max': 'Shop Setting: Max Product Spend',
    'automation.metric.shopify.shop.setting.automation.daily_management.losing_store_product_profit_min': 'Shop Setting: Losing Store Min Product Profit ',

    'automation.metric.facebook-spend.ad.today': 'Facebook Ad Spend: Today',
    'automation.metric.facebook-adSet-min-spend-percent.last_7d': 'Facebook AdSet Min Spend Percent: Last 7 days',
    'automation.metric.facebook-roas.ad.last_7d': 'Facebook Ad ROAS: Last 7 days',
    'automation.metric.facebook-roas.ad.today': 'Facebook Ad ROAS: Today',
    'automation.metric.facebook-pixel-roas.ad.last_7d': 'Facebook Pixel Ad ROAS: Last 7 days',
    'automation.metric.facebook-pixel-roas.ad.today': 'Facebook Pixel Ad ROAS: Today',
    'automation.metric.facebook-profit-margin.ad.today': 'Facebook Ad Profit Margin: Today',
    'automation.metric.facebook-profit.ad.today': 'Facebook Ad Profit: Today',
    'automation.metric.facebook-purchases-amount.ad.today': 'Facebook Ad Purchases Amount: Today',
    'automation.metric.facebook-ad-max-profit-loss-today': 'Facebook Max Ad Profit Loss: Today',
    'automation.metric.facebook-ad-max-pixel-profit-loss-today': 'Algoma Pixel Max Ad Profit Loss: Today',
    'automation.metric.facebook-profit.ad.last_7d': 'Facebook Ad Profit Loss: Last 7 days',

    'automation.metric.shopify.shop.setting.automation.ad_scale_by_cpa.daily_budget': 'Scale by CPA Setting: Daily Budget',
    'automation.metric.algoma-pixel-cpa.ad.today': 'Ad Algoma CPA: Today',
    'automation.metric.shopify.shop.setting.automation.ad_scale_by_cpa.algoma_target_cpa': 'Scale by CPA Setting: Algoma Target CPA',
    'automation.metric.facebook-cpa.ad.last_7d': 'Facebook Ad CPA: Last 7 Days',
    'automation.metric.shopify.shop.setting.automation.ad_scale_by_cpa.facebook_target_cpa': 'Scale by CPA Setting: Facebook Target CPA',
    'automation.metric.facebook-purchases-count.ad.today': 'Facebook Ad Purchases Count: Today',
    'automation.metric.shopify.shop.setting.automation.ad_scale_by_cpa.daily_budget_stop_loss': 'Scale by CPA Setting: Daily Budget Stop Loss',
    'automation.metric.static-value.btn_scale_by_ad_cpa_strg_2': 'Static: btn_scale_by_ad_cpa_strg_2',
    'automation.metric.algoma-pixel-purchases-count.ad.today': 'Ad Algoma Purchases Count: Today',
    'automation.metric.facebook-purchases-count.ad.last_7d': 'Facebook Ad Purchases Count: Last 7 Days',
    'automation.metric.facebook-spend.ad.last_7d': 'Facebook Ad Spend: Last 7 Days',
    'automation.metric.algoma-pixel-roas.ad.today': 'Ad Algoma ROAS: Today',
    'automation.metric.algoma-pixel-roas.ad.last_7d': 'Ad Algoma ROAS: Last 7 Days',
    // 'xxxx': 'xxxx',
  },
};




const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  // missingWarn: false,
  // fallbackWarn: false,
  messages
});

export default i18n;
