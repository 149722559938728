import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  list(state: State): any[];
  valueTextColorClassMap(state: State): Record<string, string>;
  valueBorderColorClassMap(state: State): Record<string, string>;
}

export const getters: GetterTree<State, any> & Getters = {
  list: (state) => state.list,
  valueTextColorClassMap: (state) => state.valueTextColorClassMap,
  valueBorderColorClassMap: (state) => state.valueBorderColorClassMap,
};