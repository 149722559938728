import { GetterTree } from 'vuex';
import { State, Stats } from './state';

export type Getters = {
  keyMetrics(state: State): null|Record<any, any>;
  keyMetricsValueIdentifiers(state: State): null|Record<string, Record<string, string>>;
  keyMetricsAreFetching(state: State): boolean;
  keyMetricsPeriod(state: State): string | null;
  stats(state: State): Stats | null;
  statsAreFetching(state: State): boolean;
}

export const getters: GetterTree<State, any> & Getters = {
  keyMetrics: (state) => state.keyMetrics,
  keyMetricsValueIdentifiers: (state) => state.keyMetricsMetadata.metricValueIdentifiers,
  keyMetricsAreFetching: (state) => state.keyMetricsMetadata.fetching,
  keyMetricsPeriod: (state) => state.keyMetricsMetadata.period,

  stats: (state) => state.stats,
  statsAreFetching: (state) => state.statsMetadata.fetching,
};