export default {

  'AuthModule.user': {}, // already cleared on logout
  'AuthModule.isAuthenticated': {}, // already cleared on logout

  'shopify.shops.selected': {
    clear(context) {
      context.commit('shopify/shops/select', null, {root: true})
    },
  },

  'shopify.shops.list': {
    clear(context) {
      context.commit('shopify/shops/setList', [], {root: true})
    },
  },

};