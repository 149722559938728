import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import AuthGuard from "@/router/guards/AuthGuard";
import GuestGuard from "@/router/guards/GuestGuard";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      middleware: [AuthGuard],
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue")
      },
      {
        path: "/v2/dashboard",
        name: "v2.dashboard",
        component: () => import("@/views/DashboardV2.vue")
      },
      {
        path: "/campaigns",
        name: "campaigns",
        component: () => import("@/views/campaigns/Campaigns.vue")
      },
      {
        path: "/facebook/change-requests",
        name: "facebook.changeRequests",
        component: () => import("@/views/facebook/ChangeRequests.vue")
      },
      {
        path: "/automation/action-logs",
        name: "automation.actionLogs",
        component: () => import("@/views/automation/ActionLog.vue")
      },
      {
        path: "/strategies",
        name: "strategies",
        component: () => import("@/views/strategies/Strategies.vue")
      },
      {
        path: "/strategy/:id",
        name: "strategy",
        component: () => import("@/views/strategies/Strategy.vue")
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/Settings.vue")
      },
      {
        path: "/integration",
        name: "integrations",
        component: () => import("@/views/integrations/Integrations.vue"),
        children: [
          {
            path: "facebook",
            name: "integration.facebook",
            component: () => import("@/views/integrations/facebook/Facebook.vue")
          },
          {
            path: "shopify",
            name: "integration.shopify",
            component: () => import("@/views/integrations/shopify/Shopify.vue")
          },
          {
            path: "google",
            name: "integration.google",
            component: () => import("@/views/integrations/google/Index.vue"),
            children: [
              {
                path: "accounts",
                name: "integration.google.accounts",
                component: () => import("@/views/integrations/google/Google.vue")
              },
              {
                path: "authenticate/token",
                name: "integration.google.authToken",
                component: () => import("@/views/integrations/google/AuthToken.vue")
              },
            ]
          },
        ]
      },
      {
        path: "/profile",
        component: () => import("@/views/NestedView.vue"),
        children: [
          // {
          //   path: "/",
          //   name: "account",
          //   component: () => import("@/views/account/unused/Account.vue"),
          // },
          // {
          //   path: "overview",
          //   name: "account.overview",
          //   component: () => import("@/views/account/unused/Overview.vue"),
          // },
          {
            path: "settings",
            name: "profile.settings",
            component: () => import("@/views/profile/Settings.vue")
          },
        ]
      },
      {
        path: "/stats",
        component: () => import("@/views/NestedView.vue"),
        children: [
          {
            path: "facebook",
            name: "stats.facebook",
            component: () => import("@/views/stats/Facebook.vue")
          },
        ]
      },
      {
        path: "order/:id",
        name: "order.journey",
        component: () => import("@/views/orders/Order.vue")
      },
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
    meta: {
      middleware: [GuestGuard],
    },
  },
  {
    /** NOTE: route does not need GuestGuard or AuthGuard as we want to render it no meter what  */
    path: "/sign-in-redirect",
    name: "sign-in-redirect",
    component: () => import("@/views/auth/SignInRedirect.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
    meta: {
      middleware: [GuestGuard],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      middleware: [GuestGuard],
    },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      middleware: [GuestGuard],
    },
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/403",
    name: "403",
    component: () => import("@/views/error/Error403.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

/**
 * @see: https://next.router.vuejs.org/guide/essentials/history-mode.html#html5-mode
 */
const router = createRouter({
  history: createWebHistory(),
  routes
});


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextRouterMiddlewareFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextRouterMiddlewareFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  // Run route middleware
  if ('middleware' in to.meta) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextRouterMiddlewareFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
