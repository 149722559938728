import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import router from '@/router';

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  avatar: string;
  fb_id: string;
  tenant_id: string;
  settings: Record<string, any>;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  authRedirectUrl = null;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  get getTenantId(): string|null {
    return this.user.tenant_id;
  }

  get getAuthRedirectUrl(): string|null {
    return this.authRedirectUrl;
  }

  get getUserSettings(): null|any {
    return this.user?.settings ?? {};
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = [];

    JwtService.saveToken(data.access_token);
    JwtService.saveTokenTTL(data.expires_in);
    JwtService.setTokenRefreshTimer();

    ApiService.setHeader();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_AUTH_REDIRECT_URL](url) {
    this.authRedirectUrl = url;
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/auth/login", credentials)
        .then(response => {
          this.context.commit(Mutations.SET_AUTH, response.data);
          this.context.dispatch(Actions.REDIRECT_ON_AUTH).catch(() => {
            // proceed only if redirect did not happen
            resolve(response.data);
          })
        })
        .catch(({ response }) => {
          if (response.status === 401) {
              this.context.commit(Mutations.SET_ERROR, ['You have entered an invalid email or password']);
          } else if (response.data.errors) {
              this.context.commit(Mutations.SET_ERROR, Object.keys(response.data.errors).map(key => response.data.errors[key]));
          } else {
              this.context.commit(Mutations.SET_ERROR, ['Whoops, something when wrong']);
          }
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {

    const isAuthenticated = this.isAuthenticated;

    this.context.commit(Mutations.PURGE_AUTH);

    return new Promise<void>((resolve) => {
      if(isAuthenticated) {
        ApiService.post("/auth/logout", {})
          .then(() => {
            this.context.dispatch('persistence/resetStore');
            resolve();
          })
          .catch(error => {
            console.warn('Failed to sign-out from server', error);
            this.context.dispatch('persistence/resetStore');
            resolve();
          });
      } else {
        this.context.dispatch('persistence/resetStore');
        resolve();
      }
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/auth/register", credentials)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("/auth/forgot-password", payload);
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return ApiService.post("/auth/reset-password", payload);
  }

  @Action
  [Actions.REFRESH_ACCESS_TOKEN]() {
    if(!this.isAuthenticated) return;

    ApiService.setHeader();
    ApiService.post("/auth/refresh", {})
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.dispatch(Actions.REDIRECT_ON_AUTH);
      })
      .catch(() => {
        alert('Failed to refresh token, please sign in');
        router.push({name: 'sign-in'});
      });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({data}) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({response}) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REDIRECT_ON_AUTH]() {
    return new Promise<void>((resolve, reject) => {
      const authRedirectUrl = this.context.getters.getAuthRedirectUrl;

      if(!this.context.getters.isUserAuthenticated || !authRedirectUrl) {
        reject();
        return;
      }

      // clear url from storage
      this.context.commit(Mutations.SET_AUTH_REDIRECT_URL, null);

      // redirect
      const authRedirectUrlHasQueryParams = authRedirectUrl.includes('?');
      location.href = authRedirectUrl + (authRedirectUrlHasQueryParams ? '&' : '?') + 'tenant='+this.context.getters.getTenantId;

      resolve();
    });
  }
}
