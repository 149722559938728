import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  list(state: State): any[];
  listIsFetching(state: State): boolean;
  selected(state: State): null | Record<string, unknown>;
  selectedUpdate(state: State): null | Record<string, unknown>;
  selectedUpdateIsValid(state: State): boolean;
  selectedIsUpdating(state: State): boolean;
  defaultSettings(state: State): null | Record<string, unknown>;
  defaultSettingsIsFetching(state: State): boolean;
}

export const getters: GetterTree<State, any> & Getters = {
  list: (state) => state.list,
  listIsFetching: (state) => state.listMetadata.isFetching,
  selected: (state) => state.selected,
  selectedUpdate: (state) => state.selectedMetadata.update,
  selectedUpdateIsValid: (state) => {
    const values = Object.values(state.selectedMetadata.validation).filter((value) => value !== null);
    if(values.length === 0) return true;
    return values.every(Boolean)
  },
  selectedIsUpdating: (state) => state.selectedMetadata.isUpdating,
  defaultSettings: (state) => state.default.settings,
  defaultSettingsIsFetching: (state) => state.defaultMetadata.settings.isFetching,
};