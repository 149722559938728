import store from '../../store';

const userCanAccessSaveChangesPages = (): boolean => {
  return store.getters.getUserSettings?.access_frontend_save_changes ?? true;
};

const rotesPermissions = {
  'settings': userCanAccessSaveChangesPages,
  'profile.settings': userCanAccessSaveChangesPages,
  'integration.facebook': userCanAccessSaveChangesPages,
  'integration.shopify': userCanAccessSaveChangesPages,
  'integration.google': userCanAccessSaveChangesPages,
  'integration.google.accounts': userCanAccessSaveChangesPages,
  'integration.google.authToken': userCanAccessSaveChangesPages,
};

export const userCanAccessRoute = (routeName: string): boolean => {
  const roteAccessCheck = (routeName in rotesPermissions) ? rotesPermissions[routeName] : null;

  if(roteAccessCheck === null) return true; // no checker - no restrictions

  if(typeof roteAccessCheck === 'boolean') return roteAccessCheck;
  if(typeof roteAccessCheck === 'function') return roteAccessCheck();

  return false;
};

export default ({ to, next }) => {
  if (store.getters.isUserAuthenticated) {
    if(userCanAccessRoute(to.name)) {
      next();
    } else {
      next('/403');
    }
  } else {
    next('/sign-in');
  }
}
