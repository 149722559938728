import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";

export interface Actions {
  fetchAuthUri(context: ActionContext<State, any>, payload): Promise<any>;
  postAuthToken(context: ActionContext<State, any>, payload): Promise<any>;
}

export const actions: ActionTree<State, any> & Actions = {

  fetchAuthUri(): Promise<AxiosResponse<any>> {
    //TODO: indicate that url is fetching
    return axios.get(`/google/account/auth`)
      .catch(() => PopupMessage.show('Failed to redirect to Google account auth', 'error', 'Ok'))
      .finally(() => {
        //TODO: indicate that url is fetched
      });
  },

  postAuthToken({commit}, payload): Promise<AxiosResponse<any>> {
    //TODO: indicate that token is being saved
    return axios.post(`/google/account/token`, payload)
      .then((response: AxiosResponse<any>) => {
        if (response.data.status === 'created' || response.data.status === 'restored') {
          // commit('xxx', response.data);
        }
        return response;
      })
      .catch(() => PopupMessage.show('Failed to save Google account token', 'error', 'Ok'));
  }

};