import { Module } from 'vuex';
import { actions } from '@/store/modules/persistence/actions';

export const storeKey = 'vuex';

export const module: Module<any, any> = {
  actions,

  namespaced: true,
};
