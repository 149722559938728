import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  list(state: State): null|any[];
  listIsFetching(state: State): boolean;
  listPaginationTotalRecordsCount(state: State): number;
  listPaginationItemsPerPage(state: State): number;
  listPaginationCurrentPage(state: State): number;
  listSortColumnName(state: State): null|string,
  listSortMetric(state: State): null|string,
  listSortOrder(state: State): null|string,
  metricValueIdentifiers(state: State): null|Record<string, Record<string, string>>;
  selected(state: State): number[];
}

export const getters: GetterTree<State, any> & Getters = {
  list: (state) => state.list,
  listIsFetching: (state) => state.listMetadata.isFetching,
  listPaginationTotalRecordsCount: (state) => state.listMetadata.pagination.totalRecordsCount,
  listPaginationItemsPerPage: (state) => state.listMetadata.pagination.itemsPerPage,
  listPaginationCurrentPage: (state) => state.listMetadata.pagination.currentPage,
  listSortColumnName: (state) => state.listMetadata.sort.columnName,
  listSortMetric: (state) => state.listMetadata.sort.metric,
  listSortOrder: (state) => state.listMetadata.sort.order,
  metricValueIdentifiers: (state) => state.listMetadata.metricValueIdentifiers,
  selected: (state) => state.selected,
};