export type ListItem = {
  label: string
  value: null|string|number|undefined,
}

export type State = {
  list: ListItem[];
  valueTextColorClassMap: Record<string, string>
  valueBorderColorClassMap: Record<string, string>
}

export const state: State = {
  list: [
    {
      label: 'Enabled',
      value: 'enabled',
    },
    {
      label: 'Emulation',
      value: 'emulation',
    },
    {
      label: 'Disabled',
      value: 'disabled',
    },
  ],

  valueTextColorClassMap: {
    enabled: 'text-success',
    emulation: 'text-warning',
    disabled: 'text-secondary',
  },
  valueBorderColorClassMap: {
    enabled: 'border-success',
    emulation: 'border-warning',
    disabled: 'border-secondary',
  }
};
