import router from "@/router";

/**
 * @description check if current route is guest route
 */
export const isGuestRoute = (): boolean => {
  /**
   * @NOTE: in current setup route is not resolved, as we use router before mounting app
   *        so we need to resolve it manually
   */
  let routeName: string = (router.currentRoute.value.name || '').toString();
  if(!routeName) {
    const route = router.resolve({ path: window.location.pathname });
    routeName = (route.name || '').toString();
  }

  return ['sign-up', 'sign-in', 'sign-in-redirect', 'forgot-password', 'reset-password'].includes(routeName);
};

export default {isGuestRoute};
