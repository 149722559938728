import {
  Module,
} from 'vuex';
import {module as presets} from "@/store/modules/automation/glossary/metricStats/presets";

export const module: Module<any, any> = {
  modules: {
    presets,
  },

  namespaced: true,
};
