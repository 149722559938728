import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import {entityTypeMap} from "@/store/enums/Entities";
import {
  FilterGroup, FilterItemPayload,
  scopeDynamicFilters
} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";
import {cloneDeep} from "lodash";

export interface Actions {
  fetch(context: ActionContext<State, any>): void;
  clearDatagrids({state, commit, dispatch}, payload: null|string[]): void;
  updateScopeDynamicFilters({rootState, commit}): void;
}

export function scopeFiltersProcessor(filters: null|FilterItemPayload[]): null|Record<string, any>[] {
  if(filters === null) return null;

  return filters.map((filter: FilterItemPayload) => {
    return {
      value: filter.value,
      scopeLevel: filter.settings?.api.entity ?? null,
      operator: filter.settings?.api.operator ?? 'equal',
      field: filter.settings?.api.field ?? null,
      metric: filter.settings?.api.metric ?? null,
    }
  }).filter(item => (
    item.value !== null &&
    (!Array.isArray(item.value) || (Array.isArray(item.value) && item.value.length !== 0))
  ));
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, dispatch}): void {
    if(!state.selectedDatagrid) return;
    dispatch('clearDatagrids');
    dispatch(`stats/facebook/${state.selectedDatagrid}/fetch`, null, {root:true});
  },

  clearDatagrids({state, dispatch}, payload: null|string[]): void {
    const datagrids = ['campaigns', 'adSets', 'ads'];

    let selectedDatagrids = payload ?? [];

    // add selected if none provided in payload
    if(selectedDatagrids.length === 0 && state.selectedDatagrid) {
      selectedDatagrids = datagrids.filter(datagrid => datagrid !== state.selectedDatagrid);
    }
    // filter invalid
    selectedDatagrids = datagrids.filter(datagrid => selectedDatagrids.includes(datagrid))

    selectedDatagrids.forEach(datagrid => {
      dispatch(`stats/facebook/${datagrid}/clear`, null, {root:true});
    })
  },

  updateScopeDynamicFilters({rootState, commit}) {
    const metricsByEntity = rootState?.automation?.glossary?.metrics?.listByEntity ?? null;
    if(!metricsByEntity) return;

    const dynamicFilters = cloneDeep(scopeDynamicFilters).map(function(filterGroup: FilterGroup) {
      const filterOptionGroupLabel = filterGroup.label;
      const filterOptionGroupList = filterGroup.list;
      const entity = filterGroup.entity;

      const metrics = (entity in metricsByEntity) ? metricsByEntity[entity] : null;

      Object.values(metrics).forEach(function(metric: any) {
        const metricType = metric.type;
        const metricLabel = metric?.labels?.datagrid ?? metric?.labels?.short ?? metric?.labels?.default;
        filterOptionGroupList.push(
          {
            gui: {
              filterLabel: `${filterOptionGroupLabel}: ${metricLabel}`,
              filterKey: `${entity}.${metricType}`,
              inputPlaceholder: `${filterOptionGroupLabel} ${metricLabel}`,
              inputType: 'numbers'
            },
            api: {
              entity: entity,
              metric: metricType,
              operator: 'equal',
            },
          }
        )
      })
      return filterGroup;
    });

    commit('setScopeDynamicFilters', dynamicFilters);
  }
};