import {
  Module,
} from 'vuex';
import {module as changeRequests} from "./changeRequests";
import {module as dropdown} from "./dropdown";

// TODO: refactor `integration/*` store modules
//  - move facebook logic here as submodules
export const module: Module<any, any> = {
  modules: {
    changeRequests,
    dropdown,
  },

  namespaced: true,
};
