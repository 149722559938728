import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";

export interface Actions {
  fetch(context: ActionContext<State, any>, payload): Promise<any>;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({commit}, payload): Promise<AxiosResponse<any>> {
    commit('setIsFetching', true);

    type RequestParams = {
      page ?: number;
      limit ?: number;
      sortOrder ?: number;
      sortColumnName ?: number;
      relations ?: any[];
    }

    const params: RequestParams = {
      page: payload.page || 1,
      limit: payload.limit || 10,
      // relations: [
      //   'ruleExecutionResultActions.result.execution'
      // ]
    };

    if('sort' in payload) {
      params.sortOrder = payload.sort.order;
      params.sortColumnName = payload.sort.columnName;
    }


    return axios.get('/facebook/change-requests',{params})
      .then((response: AxiosResponse<any>) => {
        commit('setList', response.data.data);
        commit('setListTotalRecordsCount', response.data.count);
      })
      .catch(() => PopupMessage.show('Failed to fetch Facebook Change Requests', 'error', 'Ok'))
      .finally(() => commit('setIsFetching', false));
  }
};