import {Storage} from "stale-while-revalidate-cache/types";
import {get, set} from "lodash";

interface SwrStorageConfig {
  key?: string,
}

export class SwrStorage implements Storage {
  key: string;
  storage: any;

  constructor(config: SwrStorageConfig) {
    this.storage = (window && window.localStorage);
    this.key = config.key ?? 'vswr';
  }

  getSavedState(): null|Record<any, any> {
    if(!this.storage) return null;

    try {
      const rawSavedState = this.storage.getItem(this.key);
      return rawSavedState ? JSON.parse(rawSavedState) : null;
      // eslint-disable-next-line no-empty
    } catch (err) {}

    return null;
  }

  resolveCacheKey(cacheKey: string): string
  {
    let result = cacheKey;

    const metadataFields = ['time', 'etag'];
    metadataFields.forEach((filed) => {
      if((new RegExp(`.*_${filed}$`)).test(result)) {
        result = `metadata.` + result.replace(`_${filed}`, `.${filed}`);
      }
    })

    return result;
  }

  getItem(cacheKey: string): unknown | null
  {
    if(!this.storage) return null;
    const cashedState = this.getSavedState();
    const resolvedCacheKey = this.resolveCacheKey(cacheKey);

    return get(cashedState, resolvedCacheKey, null);
  }

  setItem(cacheKey: string, cacheValue: any): void {
    if(!this.storage) return;
    const cashedState = this.getSavedState() ?? {};
    const resolvedCacheKey = this.resolveCacheKey(cacheKey);

    set(cashedState, resolvedCacheKey, cacheValue);

    this.storage.setItem(this.key, JSON.stringify(cashedState));
  }
}