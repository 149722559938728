export type State = {
  list: any[],
  listMetadata: {
    isFetching: boolean,
    pagination: {
      totalRecordsCount: number,
    }
  },
}

export const state: State = {
  list: [],
  listMetadata: {
    isFetching: false,
    pagination: {
      totalRecordsCount: 0,
    }
  },
};
