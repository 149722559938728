import { Module } from 'vuex';

import {state} from "./state";
import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

export const module: Module<any, any> = {
  state,
  mutations,
  getters,
  actions,

  namespaced: true,
};