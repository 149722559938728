import { MutationTree } from 'vuex';
import { State } from './state';

export type Mutations<S = State> = {
  setList(state: S, payload: null | Record<string, Record<string, any>>): void;
  setListIsFetching(state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setList(state: State, payload: null | Record<string, Record<string, any>>) {
    state.list = payload;
  },
  setListIsFetching(state: State, payload: boolean) {
    state.listMetadata.isFetching = payload;
  },
};