import {entityTypeMap} from "@/store/enums/Entities";

export enum labels {
  campaigns = 'Campaigns',
  adSets = 'Ad sets',
  ads = 'Ads',
}

export enum entities {
  campaigns = entityTypeMap.campaign,
  adSets = entityTypeMap.adSet,
  ads = entityTypeMap.ad,
}

