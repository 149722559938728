import {
  Module,
} from 'vuex';
import {module as auth} from "./auth";
import {module as accounts} from "./accounts";
import {module as adAccounts} from "./adAccounts";
import {module as dropdown} from "./dropdown";


export const module: Module<any, any> = {
  modules: {
    auth,
    accounts,
    adAccounts,

    dropdown,
  },

  namespaced: true,
};
