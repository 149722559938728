export type State = {
  list: any[];
  listMetadata: {
    isFetching: boolean,
  },
  selectedMetadata: {
    update: null|Record<string, any>
    isUpdating: boolean,
    validation: Record<string, null|boolean>,
  }
  selected: null | Record<string, unknown>,
  default: {
    settings: null | Record<string, unknown>,
  },
  defaultMetadata: {
    settings: {
      isFetching: boolean,
    }
  },
}

export const state: State = {
  list: [],
  listMetadata: {
    isFetching: false,
  },
  selected: null,
  selectedMetadata: {
    update: null,
    isUpdating: false,
    validation: {},
  },
  default: {
    settings: null,
  },
  defaultMetadata: {
    settings: {
      isFetching: false,
    }
  }
};
