export type State = {
  isFetching: boolean;
  list: any[];
  listTotalRecordsCount: number;
}

export const state: State = {
  isFetching: false,
  list: [],
  listTotalRecordsCount: 0,
};
