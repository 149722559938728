
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
  mounted() {
    document.title = "Algoma - Facebook Automation";
    window.Apex = {colors: ['#7209b7', '#ffb703', '#4361ee', '#3a0ca3', '#4cc9f0']} // todo: move somewhere
  },
});

declare global { //todo: move somewhere
  interface Window {
    Apex:any;
  }
}

