import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
// import axios, {AxiosResponse} from "axios";
// import PopupMessage from "@/core/helpers/PopupMessage";
import qs from "qs";

export interface Actions {
  fetch(context: ActionContext<State, any>, payload: null|Record<string, any>): Promise<any>|null;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit}, payload: null|Record<string, any>): Promise<any>|null
  {
    const forceLoad = (payload && payload.force);

    if(!forceLoad && state.list.length > 0) return null;

    commit('setListIsFetching', true);

    const paramsSerializer = params => {
      return qs.stringify(params)
    }
    const params = {
      // relations: [
      //   'facebookAdAccount'
      // ]
    };

    const staticData = [
      {
        id: 1,
        progress: "60",
        tags: ['ROAS control', 'Daily Management'],
        icon: "/media/illustrations/dozzy-1/7.png",
        title: "Daily Ad Cost Control",
        spend: 8740000,
        followers: 39,
        description: 'Our most popular strategy, control your daily markting cost'
      },
      {
        id: 2,
        progress: "100",
        tags: ['Scaling', 'CPA Control'],
        icon: "/media/illustrations/dozzy-1/1.png",
        title: "Scale by CPA",
        spend: 1600300,
        followers: 4,
        description: 'Control & Optimize based on accurate CPA stats, scaling on best performing ads'
      },
      {
        id: 6,
        progress: "100",
        tags: ['Budget Control'],
        icon: "/media/illustrations/unitedpalms-1/12.png",
        title: "Stop Loss Control",
        spend: 2730000,
        followers: 17,
        description: 'Low performing store? do not lose a dollar more than planned, and scale when the budget is positive'
      },
      {
        id: 3,
        progress: "100",
        tags: ['Optimization', 'ROAS Control'],
        icon: "/media/illustrations/dozzy-1/6.png",
        title: "Blue Ocean",
        spend: 4720000,
        followers: 13,
        description: 'Optimize your ads towards quality vs quantity, let the machine keep your best ads'
      },
      {
        id: 4,
        progress: "100",
        tags: ['Scaling', 'Aggressive'],
        icon: "/media/illustrations/unitedpalms-1/20.png",
        title: "Super Growth",
        spend: 2300000,
        followers: 7,
        description: 'Push your sales to the maximum using all available positive signals'
      },
      {
        id: 5,
        progress: "100",
        tags: ['Goal Optimization', 'Daily Management'],
        icon: "/media/illustrations/unitedpalms-1/17.png",
        title: "Hit The Numbers",
        spend: 857000,
        followers: 2,
        description: 'In the end, a vision without the ability to execute it is probably a hallucination'
      }
    ]

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commit('setList', staticData)
        resolve('done');
      }, 300);
    });


    // return axios.get('/strategies', {params, paramsSerializer})
    //   .then((response: AxiosResponse<any>) => {
    //     commit('setList', response.data.data);
    //     commit('setListPaginationTotalRecordsCount', response.data.count);
    //   })
    //   .catch(() => PopupMessage.show('Failed to fetch google accounts', 'error', 'Ok'))
    //   .finally(() => commit('setListIsFetching', false));
  },

};