import {ActionContext, ActionTree} from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import {Mutations} from "@/store/enums/StoreEnums";



export interface Actions {
  update(context: ActionContext<State, any>, payload: Record<string, any>): Promise<AxiosResponse<any>>,
  updateEmail(context: ActionContext<State, any>, payload: Record<string, any>): Promise<AxiosResponse<any>>,
  updatePassword(context: ActionContext<State, any>, payload: Record<string, any>): Promise<AxiosResponse<any>>,
  delete(context: ActionContext<State, any>, payload: Record<string, any>): Promise<AxiosResponse<any>>,
}

export const actions: ActionTree<State, any> & Actions = {

  update({commit}, payload: Record<string, any>): Promise<AxiosResponse<any>> {
    return axios.post(`/user/update`, payload)
      .then((response: AxiosResponse<any>) => {
        commit(Mutations.SET_USER, response.data.user, {root: true})

        return response;
      });
  },

  updateEmail({commit}, payload: Record<string, any>): Promise<AxiosResponse<any>> {
    return axios.post(`/user/update-email`, payload)
      .then((response: AxiosResponse<any>) => {
        commit(Mutations.SET_USER, response.data.user, {root: true})
        return response;
      });
  },

  updatePassword({commit}, payload: Record<string, any>): Promise<AxiosResponse<any>> {
    return axios.post(`/user/update-password`, payload)
      .then((response: AxiosResponse<any>) => {
        commit(Mutations.SET_USER, response.data.user, {root: true})
        return response;
      });
  },

  delete(context, payload: Record<string, any>): Promise<AxiosResponse<any>> {
    return axios.post(`/user/delete`, payload)
      .then((response: AxiosResponse<any>) => {
        return response;
      });
  }

};