import {
  Module,
} from 'vuex';
import {module as ruleExecutionResults} from "@/store/modules/automation/ruleExecutionResults";
import {module as ruleExecutionResultActions} from "@/store/modules/automation/ruleExecutionResultActions";
import {module as metricStats} from "@/store/modules/automation/metricStats";
import {module as glossary} from "@/store/modules/automation/glossary";
import {module as dropdown} from "@/store/modules/automation/dropdown";

export const module: Module<any, any> = {
  modules: {
    ruleExecutionResults,
    ruleExecutionResultActions,
    metricStats,
    glossary,
    dropdown,
  },

  namespaced: true,
};
