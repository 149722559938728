import {
  Module,
} from 'vuex';
import {module as adAccounts} from "./adAccounts";


export const module: Module<any, any> = {
  modules: {
    adAccounts,
  },

  namespaced: true,
};