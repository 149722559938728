import { MutationTree } from 'vuex';
import { State } from './state';

export type Mutations<S = State> = {
  setList(state: S, payload: null | Record<string, Record<string, any>>): void;
  setListIsFetching(state: S, payload: boolean): void;
  setListIsUpdating(state: S, payload: boolean): void;
  updatePreset(state: S, payload: Record<string, any>): void;
  persistPreset(state: S, payload: Record<string, any>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setList(state: State, payload: null | Record<string, Record<string, any>>) {
    state.list = payload;
  },
  setListIsFetching(state: State, payload: boolean) {
    state.listMetadata.isFetching = payload;
  },
  setListIsUpdating(state: State, payload: boolean) {
    state.listMetadata.isUpdating = payload;
  },
  updatePreset(state: State, payload: Record<string, any>) {
    const preset = payload.preset;
    const entity = payload.entity;
    const list = payload.list;

    if(state.listMetadata.updated === null) state.listMetadata.updated = {};
    if(!(preset in state.listMetadata.updated)) state.listMetadata.updated[preset] = {};
    state.listMetadata.updated[preset][entity] = list;
  },
  persistPreset(state: State, payload: Record<string, any>) {
    const preset = payload.preset;
    const entity = payload.entity;
    const list = payload.list;

    if(state.list === null) state.list = {};
    if(!(preset in state.list)) state.list[preset] = {};
    state.list[preset][entity] = list;
  },
};