import {
  Module,
} from 'vuex';
import {module as datePresets} from "./datePresets";
import {module as tactics} from "./tactics";
import {module as ruleExecutionResultActions} from "./ruleExecutionResultActions";

export const module: Module<any, any> = {
  modules: {
    datePresets,
    tactics,
    ruleExecutionResultActions,
  },

  namespaced: true,
};