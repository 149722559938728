import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import swr from "@/store/cache/swr";

export interface Actions {
  fetch(context: ActionContext<State, any>, payload): Promise<void | Record<any, any>>|null;
  clear(context: ActionContext<State, any>): void;
}

const cacheKey = 'facebook.dropdown.adAccounts.list';

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit}): Promise<void | Record<any, any>>|null {
    return swr.cachedRequest({
      cacheKey,
      fetch: (headers): Promise<AxiosResponse> => {

        commit('setListIsFetching', true);
        return axios.get('/facebook/dropdown-items/ad-accounts', {headers})
        .catch(() => PopupMessage.show('Failed to fetch Facebook Ad Accounts', 'error', 'Ok'))
        .finally(() => commit('setListIsFetching', false));

      },
      transformResponse: (response: AxiosResponse): Record<string, any> => {
        return response.data;
      },
      shouldCommitCache: (data: undefined|Record<string, any>): boolean => {
        return (state.list.length === 0 && data !== null);
      },
      commit: (data: undefined|Record<string, any>) => {
        commit('setList', data);
      }
    });
  },

  clear({commit}): void {
    // clear list
    commit('setList', []);
    // clear cached list
    swr.storage.setItem(cacheKey, null);
  }


};