import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import qs from "qs";
import PopupMessage from "@/core/helpers/PopupMessage";
import {scopeFiltersProcessor} from "@/store/modules/stats/facebook/actions";
import {merge} from "lodash";
import {entityTypeMap} from "@/store/enums/Entities";

export interface Actions {
  fetchKeyMetrics(context: ActionContext<State, any>, payload: null|Record<string, any>): Promise<AxiosResponse<any>>|null;
  fetchStats(context: ActionContext<State, any>, payload: null|Record<string, any>): Promise<AxiosResponse<any>>|null;
}

export const actions: ActionTree<State, any> & Actions = {

  fetchKeyMetrics({state, commit, rootGetters}, payload = null): Promise<AxiosResponse<any>>|null {
    commit('setKeyMetrics', null);
    commit('setKeyMetricsValueIdentifiers', null);

    const selectedShop = rootGetters['shopify/shops/selected'];
    if(!selectedShop || !selectedShop.facebookAdAccounts || !selectedShop.facebookAdAccounts.length) {
      return null;
    }

    commit('setKeyMetricsAreFetching', true);

    // ShopifyShop ID
    const scopeFilters = scopeFiltersProcessor([
      {
        key: `${entityTypeMap.shopifyShop}.id`,
        value: selectedShop.id,
        settings: {
          api: {
            entity: entityTypeMap.shopifyShop,
            field: 'id',
            operator: 'equal',
          },
        }
      }
    ]);

    //rootScopeFilters = [
    //         ...rootScopeFilters,

    //       ];
    //     }

    // const metricFilters = rootState.stats.facebook.metric.filters;
    const metricPeriod = state.keyMetricsMetadata.period;

    let params = {
      scope: {
        level: 'shopify.shop',
        filters: scopeFilters,
      },
      metric: {
        preset: 'dashboard-key-metrics',
        period: metricPeriod,
      }
    };

    const payloadParams = payload?.params;
    if(payloadParams) params = merge({}, params, payloadParams);

    return axios.post('/automation/metric-stats', params)
    .then(async (response: AxiosResponse<any>) => {
      const shop = response.data.data.find(item => item.id === selectedShop.id);
      if(!shop) return;
      commit('setKeyMetrics', shop?.metrics_data ?? {});
      commit('setKeyMetricsValueIdentifiers', response.data.metricValueIdentifiers);
    })
    .catch((error) => {
      console.log(error);
      return PopupMessage.show('Failed to fetch dashboard stats', 'error', 'Ok')
    })
    .finally(() => commit('setKeyMetricsAreFetching', false));
  },

  fetchStats({state, commit, rootGetters}, payload = null): Promise<AxiosResponse<any>>|null {

    const forceLoad = payload?.force;
    if(
      !forceLoad &&
      !state.statsMetadata.fetching &&
      state.stats !== null
    ) return null;

    commit('setStats', null);

    const selectedShop = rootGetters['shopify/shops/selected'];
    if(!selectedShop || !selectedShop.facebookAdAccounts || !selectedShop.facebookAdAccounts.length) {
      return null;
    }

    commit('setStatsAreFetching', true);

    type RequestParams = {
      period: null|string,
    }

    const paramsSerializer = params => {
      return qs.stringify(params)
    }

    let params: RequestParams = {
      period: state.keyMetricsMetadata.period ?? 'today',
    };
    const payloadParams = payload?.params;
    if(payloadParams) params = {...params, ...payloadParams};

    return axios.get(`/shopify/shop/${selectedShop.id}/dashboard/stats`,{params, paramsSerializer})
      .then((response: AxiosResponse<any>) => {
        commit('setStats', response.data);
      })
      .catch(() => PopupMessage.show('Failed to fetch dashboard stats', 'error', 'Ok'))
      .finally(() => commit('setStatsAreFetching', false));
  },

};