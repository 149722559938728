export type ListItem = {
  label: string
  value: null|string|number|undefined,
  status: string,
}

export type State = {
  list: ListItem[];
  listMetadata: {
    isFetching: boolean,
  },
}

export const state: State = {
  list: [],
  listMetadata: {
    isFetching: false,
  },
};
