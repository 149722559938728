import {entityTypeMap} from "@/store/enums/Entities";

export type FilterItemSettings = {
  gui?: {
    filterLabel: string,
    filterKey: string,
    inputPlaceholder: string,
    inputType: string,
    inputOptions?: Record<any, any>[],
  },
  api: {
    entity?: string|null,
    field?: string,
    operator?: string
    metric?: string
  },
}
export type FilterGroup = {
  entity: string,
  label: string,
  list: FilterItemSettings[]
};
export type FilterGroups = FilterGroup[];

export type FilterItemPayload = {
  key: string,
  settings?: FilterItemSettings,
  value: any,
}

const campaignEffectiveStatuses = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PAUSED', label: 'Paused' },
  { value: 'DELETED', label: 'Deleted' },
  { value: 'ARCHIVED', label: 'Archived' },
  { value: 'IN_PROCESS', label: 'In Process' },
  { value: 'WITH_ISSUES', label: 'With Issues' },
];

const campaignObjectives = [
  { value: 'APP_INSTALLS', label: 'App Installs' },
  { value: 'BRAND_AWARENESS', label: 'Brand Awareness' },
  { value: 'CONVERSIONS', label: 'Conversions' },
  { value: 'EVENT_RESPONSES', label: 'Event Responses' },
  { value: 'LEAD_GENERATION', label: 'Lead Generation' },
  { value: 'LINK_CLICKS', label: 'Link Clicks' },
  { value: 'LOCAL_AWARENESS', label: 'Local Awareness' },
  { value: 'MESSAGES', label: 'Messages' },
  { value: 'OFFER_CLAIMS', label: 'Offer Claims' },
  { value: 'PAGE_LIKES', label: 'Page Likes' },
  { value: 'POST_ENGAGEMENT', label: 'Post Engagement' },
  { value: 'PRODUCT_CATALOG_SALES', label: 'Product Catalog Sales' },
  { value: 'REACH', label: 'Reach' },
  { value: 'STORE_VISITS', label: 'Store Visits' },
  { value: 'VIDEO_VIEWS', label: 'Video Views' },
];

const adSetEffectiveStatuses = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PAUSED', label: 'Paused' },
  { value: 'DELETED', label: 'Deleted' },
  { value: 'ARCHIVED', label: 'Archived' },
  { value: 'IN_PROCESS', label: 'In Process' },
  { value: 'WITH_ISSUES', label: 'With Issues' },
  { value: 'CAMPAIGN_PAUSED', label: 'Campaign Paused' },
];

const adEffectiveStatuses = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PAUSED', label: 'Paused' },
  { value: 'DELETED', label: 'Deleted' },
  { value: 'ARCHIVED', label: 'Archived' },
  { value: 'IN_PROCESS', label: 'In Process' },
  { value: 'PENDING_REVIEW', label: 'Pending Review' },
  { value: 'DISAPPROVED', label: 'Disapproved' },
  { value: 'PREAPPROVED', label: 'Preapproved' },
  { value: 'PENDING_BILLING_INFO', label: 'Pending Billing Info' },
  { value: 'CAMPAIGN_PAUSED', label: 'Campaign Paused' },
  { value: 'ADSET_PAUSED', label: 'Ad Set Paused' },
];

export const scopeDynamicFilters: FilterGroups = [
  {
    entity: entityTypeMap.campaign,
    label: 'Campaign',
    list: [
      {
        gui: {
          filterLabel: 'Campaign: ID',
          filterKey: `${entityTypeMap.campaign}.ids`,
          inputPlaceholder: 'Campaign ID',
          inputType: 'ids',
        },
        api: {
          entity: entityTypeMap.campaign,
          field: 'id',
          operator: 'in'
        },
      },
      {
        gui: {
          filterLabel: 'Campaign: Name',
          filterKey: `${entityTypeMap.campaign}.names`,
          inputPlaceholder: 'Campaign Name',
          inputType: 'strings'
        },
        api: {
          entity: entityTypeMap.campaign,
          field: 'name',
          operator: 'contain_any'
        },
      },
      {
        gui: {
          filterLabel: 'Campaign: Delivery',
          filterKey: `${entityTypeMap.campaign}.effectiveStatuses`,
          inputPlaceholder: 'Campaign Delivery',
          inputType: 'options',
          inputOptions: campaignEffectiveStatuses
        },
        api: {
          entity: entityTypeMap.campaign,
          field: 'effective_status',
          operator: 'in'
        },
      },
      {
        gui: {
          filterLabel: 'Campaign: Objective',
          filterKey: `${entityTypeMap.campaign}.objectives`,
          inputPlaceholder: 'Campaign Objective',
          inputType: 'options',
          inputOptions: campaignObjectives
        },
        api: {
          entity: entityTypeMap.campaign,
          field: 'objective',
          operator: 'in'
        },
      },
    ],
  },
  {
    entity: entityTypeMap.adSet,
    label: 'Ad set',
    list: [
      {
        gui: {
          filterLabel: 'Ad set: ID',
          filterKey: `${entityTypeMap.adSet}.ids`,
          inputPlaceholder: 'Ad set ID',
          inputType: 'ids',
        },
        api: {
          entity: entityTypeMap.adSet,
          field: 'id',
          operator: 'in'
        },
      },
      {
        gui: {
          filterLabel: 'Ad set: Name',
          filterKey: `${entityTypeMap.adSet}.names`,
          inputPlaceholder: 'Ad set Name',
          inputType: 'strings'
        },
        api: {
          entity: entityTypeMap.adSet,
          field: 'name',
          operator: 'contain_any'
        },
      },
      {
        gui: {
          filterLabel: 'Ad set: Delivery',
          filterKey: `${entityTypeMap.adSet}.effectiveStatuses`,
          inputPlaceholder: 'Ad set Delivery',
          inputType: 'options',
          inputOptions: adSetEffectiveStatuses
        },
        api: {
          entity: entityTypeMap.adSet,
          field: 'effective_status',
          operator: 'in'
        },
      }
    ],
  },
  {
    entity: entityTypeMap.ad,
    label: 'Ad',
    list: [
      {
        gui: {
          filterLabel: 'Ad: ID',
          filterKey: `${entityTypeMap.ad}.ids`,
          inputPlaceholder: 'Ad ID',
          inputType: 'ids',
        },
        api: {
          entity: entityTypeMap.ad,
          field: 'id',
          operator: 'in'
        },
      },
      {
        gui: {
          filterLabel: 'Ad: Name',
          filterKey: `${entityTypeMap.ad}.names`,
          inputPlaceholder: 'Ad Name',
          inputType: 'strings'
        },
        api: {
          entity: entityTypeMap.ad,
          field: 'name',
          operator: 'contain_any'
        },
      },
      {
        gui: {
          filterLabel: 'Ad: Delivery',
          filterKey: `${entityTypeMap.ad}.effectiveStatuses`,
          inputPlaceholder: 'Ad Delivery',
          inputType: 'options',
          inputOptions: adEffectiveStatuses
        },
        api: {
          entity: entityTypeMap.ad,
          field: 'effective_status',
          operator: 'in'
        },
      }
    ],
  },
];