import { ActionTree, ActionContext } from 'vuex';
import {ResetModuleOption} from "vuex-extensions";
import persistedPaths from "./paths";

export interface Actions {
  reset?(options?: ResetModuleOption): void;
  resetStore(context: ActionContext<any, any>, payload: null|ResetModuleOption[]): Promise<any>;
  clear(context: ActionContext<any, any>, payload: null|string[]): Promise<any>;
}

export const actions: ActionTree<any, any> & Actions = {

  clear(actionContext, payload: null|string[] = null): Promise<any> {
    return new Promise<void>((resolve) => {

      Object.keys(persistedPaths).forEach((path) => {
        if(payload && !payload.includes(path)) return;

        const pathConfig = persistedPaths[path];

        if(!('clear' in pathConfig)) return;
        pathConfig.clear(actionContext)
      })

      resolve();
    });
  },

  resetStore(actionContext, payload): Promise<any> {
    return new Promise<void>((resolve) => {
      const storage = (window && window.localStorage);
      if(storage) {
        storage.removeItem('vuex');
        storage.removeItem('vswr');
      }


      if(this?.reset) this?.reset();

      resolve();
    });
  }


};