export type State = {
  list: null | Record<string, Record<string, any>>;
  listMetadata: {
    isFetching: boolean,
  },
}

export const state: State = {
  list: null,
  listMetadata: {
    isFetching: false,
  },
};
