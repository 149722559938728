import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import swr from "@/store/cache/swr";

export interface Actions {
  fetch(context: ActionContext<State, any>, payload): Promise<void | Record<any, any>>|null;
}

export const actions: ActionTree<State, any> & Actions = {

  fetch({state, commit}): Promise<void | Record<any, any>>|null {
    const cacheKey = 'automation.glossary.metric-stats.presets.list';
    return swr.cachedRequest({
      cacheKey,
      fetch: (headers): Promise<AxiosResponse> => {

        commit('setListIsFetching', true);
        return axios.get('/automation/glossary/metric-stats/presets', {headers})
          .catch(() => PopupMessage.show('Failed to fetch metric-stats presets glossary', 'error', 'Ok'))
          .finally(() => commit('setListIsFetching', false));

      },
      transformResponse: (response: AxiosResponse): Record<string, any> => {
        return response.data;
      },
      shouldCommitCache: (data: undefined|Record<string, any>): boolean => {
        return (state.list === null && data !== null);
      },
      commit: (data: undefined|Record<string, any>) => {
        commit('setList', data);
      }
    });
  },


};