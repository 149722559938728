export type Stats = {
  CpaRoasSales ?: any
  SalesByHour ?: any
  Campaign ?: any
  Product ?: any
  TopSources ?: any
  UtmSources ?: any
}

export type State = {
  keyMetrics: null | Record<any, any>,
  keyMetricsMetadata: {
    fetching: boolean,
    period: null|string,
    metricValueIdentifiers: null|Record<string, Record<string, string>>,
  },
  stats: null | Stats,
  statsMetadata: {
    fetching: boolean,
  }
}

export const state: State = {
  keyMetrics: null,
  keyMetricsMetadata: {
    fetching: false,
    period: null,
    metricValueIdentifiers: null,
  },
  stats: null,
  statsMetadata: {
    fetching: false,
  },
};
