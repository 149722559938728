import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  list(state: State): null | Record<string, Record<string, any>>;
  listIsFetching(state: State): boolean;
  listIsUpdating(state: State): boolean;
  updatedList(state: State): null | Record<string, Record<string, any>>;
}

export const getters: GetterTree<State, any> & Getters = {
  list: (state) => state.list,
  listIsFetching: (state) => state.listMetadata.isFetching,
  listIsUpdating: (state) => state.listMetadata.isUpdating,
  updatedList: (state) => state.listMetadata.updated,
};