import { GetterTree } from 'vuex';
import { State } from './state';
import {
  scopeDynamicFilters,
  FilterItemPayload,
  FilterGroups
} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";


export type Getters = {
  scopeFilters(state: State): FilterItemPayload[] | null;
  metricPeriod(state: State): null | string;
  metricPeriodRange(state: State): null | Record<any, any>;
  metricPreset(state: State): null | string;
  modalShopifyOrders(state: State): Record<any, any>;
  modalColumns(state: State): Record<any, any>;
  scopeDynamicFilters(state: State): FilterGroups;
}

export const getters: GetterTree<State, any> & Getters = {
  selectedDatagrid: (state) => state.selectedDatagrid,
  scopeFilters: (state) => state.scope.filters,
  metricPeriod: (state) => state.metric.period,
  metricPeriodRange: (state) => state.metric.periodRange,
  metricPreset: (state) => state.metric.preset,
  modalShopifyOrders: (state) => state.modals.shopify.orders,
  modalColumns: (state) => state.modals.columns,
  scopeDynamicFilters: (state) => state.scopeMetadata.dynamicFilters ?? scopeDynamicFilters,
};