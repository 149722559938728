import { MutationTree } from 'vuex';
import { State } from './state';
import {entityTypeMap} from "@/store/enums/Entities";
import {collect} from "collect.js";

export type Mutations<S = State> = {
  setList(state: S, payload: null | Record<string, Record<string, any>>): void;
  setListIsFetching(state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setList(state: State, payload: null | Record<string, Record<string, any>>) {
    // update list
    state.list = payload;

    // update grouped list
    state.listByEntity = (payload === null) ? null : collect(Object.values(entityTypeMap))
      .mapWithKeys(function(entity) {
        const metricsForEntity = collect(payload).filter(function(metric: any) {
          const metricObjectTypes = metric?.objectTypes ?? null;
          if(!metricObjectTypes) return false;

          return metricObjectTypes.includes(entity);
        }).all();
        return [entity, metricsForEntity];
      }).all() as Record<any, any>;

  },
  setListIsFetching(state: State, payload: boolean) {
    state.listMetadata.isFetching = payload;
  },
};