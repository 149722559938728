import {
  Module,
} from 'vuex';
import {module as metrics} from "@/store/modules/automation/glossary/metrics";
import {module as metricStats} from "@/store/modules/automation/glossary/metricStats";

export const module: Module<any, any> = {
  modules: {
    metrics,
    metricStats,
  },

  namespaced: true,
};
