import { MutationTree } from 'vuex';
import { State } from './state';
import {updateListItemById, UpdateListItemByIdPayload} from '@/store/common/mutation/list';

export type Mutations<S = State> = {
  setList(state: S, payload: any[]): void;
  setListIsFetching(state: S, payload: boolean): void;
  updateListItem(state: S, payload: UpdateListItemByIdPayload): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setList(state: State, payload: any[]) {
    state.list = payload;
  },
  setListIsFetching(state: State, payload: boolean) {
    state.listMetadata.isFetching = payload;
  },
  setListPaginationTotalRecordsCount(state: State, payload: number) {
    state.listMetadata.pagination.totalRecordsCount = payload;
  },

  updateListItem(state: State, payload: UpdateListItemByIdPayload) {
    updateListItemById(state, payload);
  },
};