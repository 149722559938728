import Swal from "sweetalert2/dist/sweetalert2.min.js";

class PopupMessage {
  static show(title: string, type = 'primary', confirmButtonText = 'Ok') {
    const buttonType = type === 'error' ? 'danger' : type;

    return Swal.fire({
      title,
      icon: type,
      buttonsStyling: false,
      confirmButtonText,
      customClass: {
        confirmButton: "btn fw-bold btn-light-"+buttonType,
      }
    });
  }
}

export default PopupMessage;
