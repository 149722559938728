export type UpdateListItemByIdPayload = {
  id: number,
  payload: Record<string, any>,
};

export type RemoveListItemByIdPayload = {
  id: number,
};

export function updateListItemById (state: Record<string, any>, payload: UpdateListItemByIdPayload) {
  if(!state.list) return;

  const id = payload.id;
  const data = payload.payload;

  const itemIndex = state.list.findIndex(item => item.id === id);
  if(itemIndex === -1) return;

  const item = state.list[itemIndex];

  state.list[itemIndex] = {...item, ...data};

  if(state.selected && state.selected.id === item.id) {
    state.selected = state.list[itemIndex];
  }
}

export function removeListItemById(state: Record<string, any>, payload: RemoveListItemByIdPayload) {
  if(!state.list) return;

  const id = payload.id;

  const itemIndex = state.list.findIndex(item => item.id === id);
  if(itemIndex === -1) return;

  state.list.splice(itemIndex, 1);
}