import { Module } from 'vuex';

import {state} from "./state";
import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

// import {module as dashboard} from "./dashboard";
// import {module as tactics} from "./tactics";

export const module: Module<any, any> = {
  state,
  mutations,
  getters,
  actions,

  modules: {
    // dashboard,
    // tactics,
  },

  namespaced: true,
};