import { GetterTree } from 'vuex';
import { State, ListFilters } from './state';

export type Getters = {
  list(state: State): any[];
  listIsFetching(state: State): boolean;
  listPaginationTotalRecordsCount(state: State): number;
  listPaginationItemsPerPage(state: State): number;
  listPaginationCurrentPage(state: State): number;
  listSortColumnName(state: State): string;
  listSortOrder(state: State): string;
  listFilters(state: State): ListFilters;
}

export const getters: GetterTree<State, any> & Getters = {
  list: (state) => state.list,
  listIsFetching: (state) => state.listMetadata.isFetching,
  listPaginationTotalRecordsCount: (state) => state.listMetadata.pagination.totalRecordsCount,
  listPaginationItemsPerPage: (state) => state.listMetadata.pagination.itemsPerPage,
  listPaginationCurrentPage: (state) => state.listMetadata.pagination.currentPage,
  listSortColumnName: (state) => state.listMetadata.sort.columnName,
  listSortOrder: (state) => state.listMetadata.sort.order,
  listFilters: (state) => state.listMetadata.filters ?? state.listMetadata.filtersDefault,
};