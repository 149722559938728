// import { createStore } from "vuex";
import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'
import createPersistedState from "vuex-persistedstate";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import FacebookIntegrationModule from "@/store/modules/integrations/FacebookIntegrationModule";
import {module as user} from "@/store/modules/user";
import {module as facebook} from "@/store/modules/facebook";
import {module as automation} from "@/store/modules/automation";
import {module as shopify} from "@/store/modules/shopify";
import {module as google} from "@/store/modules/google";
import {module as strategies} from "@/store/modules/strategies";
import {module as persistence, storeKey as persistenceStoreKey} from "@/store/modules/persistence";
import {module as stats} from "@/store/modules/stats";
import persistedPaths from "@/store/modules/persistence/paths";

config.rawError = true;

const dataState = createPersistedState({
  key: persistenceStoreKey,
  paths: Object.keys(persistedPaths),
});

const store = createStore(Store, {
  modules: {
    persistence,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    FacebookIntegrationModule,
    user,
    facebook,
    shopify,
    google,
    automation,
    strategies,
    stats,
  },
  plugins: [dataState],
});

export default store;
