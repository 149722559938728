import { MutationTree } from 'vuex';
import {State, Stats} from './state';

export type Mutations<S = State> = {
  setKeyMetrics(state: S, payload: null|Record<any, any>): void;
  setKeyMetricsValueIdentifiers(state: S, payload: null|Record<string, Record<string, string>>): void;
  setKeyMetricsAreFetching(state: S, payload: boolean): void;
  setKeyMetricsPeriod(state: S, payload: string): void;

  setStats(state: S, payload: Stats): void;
  setStatsAreFetching(state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setKeyMetrics(state: State, payload: null|Record<any, any>) {
    state.keyMetrics = payload;
  },
  setKeyMetricsValueIdentifiers(state: State, payload: null|Record<string, Record<string, string>>) {
    state.keyMetricsMetadata.metricValueIdentifiers = payload;
  },
  setKeyMetricsAreFetching(state: State, payload: boolean) {
    state.keyMetricsMetadata.fetching = payload;
  },
  setKeyMetricsPeriod(state: State, payload: string) {
    state.keyMetricsMetadata.period = payload;
  },

  setStats(state: State, payload: Stats|null) {
    state.stats = payload;
  },
  setStatsAreFetching(state: State, payload: boolean) {
    state.statsMetadata.fetching = payload;
  },
};