import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

// imports for app initialization
import ApiService from "@/core/services/ApiService";
import JwtService from '@/core/services/JwtService';
import Filters from "@/core/filters";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

/*
 * NOTE: bootstrap-vue-3 already includes original bootstrap logic
 * including bootstrap separately will cause all event handlers to fire twice
 */
import BootstrapVue3 from 'bootstrap-vue-3';

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(BootstrapVue3);

Filters.init(app);
ApiService.init(app);
JwtService.init();
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");